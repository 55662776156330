import { FormlyFieldConfig } from "@ngx-formly/core";
import { FormlyJsonschemaOptions } from "@ngx-formly/core/json-schema/formly-json-schema.service";

export const defaultJsonschemaOptions: FormlyJsonschemaOptions = {
  map: (mappedField: FormlyFieldConfig, mapSource: any): FormlyFieldConfig => {
    // default expression property for all fields
    mappedField.expressionProperties = {
      ...mappedField.expressionProperties,
      "templateOptions.disabled": "formState.disabled"
    };

    // Map format widgets
    const appliedTypes = ["string"];
    let intersecion = false;
    if (Array.isArray(mapSource.type)) {
      intersecion = appliedTypes.filter(element => mapSource.type.includes(element)).length > 0;
    } else if (typeof mapSource.type === "string") {
      intersecion = appliedTypes.includes(mapSource.type);
    }
    if (intersecion) {
      // datepicker
      if (mapSource?.format === "date") {
        mappedField.type = mappedField.type === "string" ? "datepicker" : mappedField.type;
      }

      // textarea
      if (mapSource?.rows || mapSource?.widget?.rows) {
        mappedField.type = "textarea";
        mappedField.templateOptions = mappedField.templateOptions ?? {};
        mappedField.templateOptions.rows = 1;
        mappedField.templateOptions.cols = mapSource.cols;
        mappedField.templateOptions.autosizeMinRows = 1;
        mappedField.templateOptions.autosizeMaxRows = mapSource.rows;
        mappedField.templateOptions.autosize = true;
      }
    }
    return mappedField;
  }
};
