<mat-form-field fxFlex>
  <mat-label>{{ to.label }}</mat-label>

  <!-- The timepicker input -->
  <input
    matTimepicker
    #timepicker="matTimepicker"
    [strict]="false"
    id="timepicker-example"
    mode="12h"
    [formControl]="formControl"
    placeholder="Please select time..."
    name="arrivalTime"
    (timeChange)="timeChangeHandler($event)"
  />

  <!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
  <mat-icon matSuffix (click)="timepicker.showDialog()">access_time</mat-icon>
</mat-form-field>
