import { EventBase } from "@vp/shared/event-aggregator";

export class CaseChatEvent extends EventBase<ChatMessage> {
  constructor(result: ChatMessage) {
    super();
    this.args = result;
  }
}

export interface ChatMessage {
  messageText: string;
  senderName: string | null;
  senderId: string | undefined;
  caseId: string | null;
}
