import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Params,
  UrlSegment
} from "@angular/router";
import { Observable, pipe } from "rxjs";
import { filter, map, shareReplay, startWith, switchMap, tap } from "rxjs/operators";

/**
 * Maps to route data from the activated route during a router navigation
 *
 * Note: ENABLE_ASYNC fixes race-condition with async pipe use
 * See: https://stackoverflow.com/a/56712551/4321097
 */
export function mapActivatedRoute(_activatedRoute: ActivatedRoute) {
  return <RouterEvent>(source: Observable<RouterEvent>): Observable<ActivatedRoute> => {
    return source.pipe(
      startWith("ENABLE_ASYNC"),
      filter(event => event === "ENABLE_ASYNC" || event instanceof NavigationEnd),
      map(() => _activatedRoute)
    );
  };
}

/**
 * Maps to route data from the activated route during a router navigation
 *
 * Note: ENABLE_ASYNC fixes race-condition with async pipe use
 * See: https://stackoverflow.com/a/56712551/4321097
 */
export function mapActivatedRouteBreadcrumbs(activatedRoute: ActivatedRoute) {
  return <RouterEvent>(
    source: Observable<RouterEvent>
  ): Observable<Array<{ label: string; url: string }>> => {
    return source.pipe(
      startWith("ENABLE_ASYNC"),
      filter(event => event === "ENABLE_ASYNC" || event instanceof NavigationEnd),
      map(_ => {
        const breadcrumbs: Array<{ label: string; url: string }> = [];
        let currentRoute: ActivatedRoute | null = activatedRoute.root;
        let label = "";
        let url = "";
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(childrenRoute => {
            if (childrenRoute.outlet === "primary") {
              const routeConfig = childrenRoute.routeConfig;
              const routeSnapshot = childrenRoute.snapshot;
              label = routeConfig?.data?.breadcrumb;
              url += routeSnapshot.url.map(segment => "/" + segment.path).join("");
              if (label) {
                breadcrumbs.push({ label, url });
              }
              currentRoute = childrenRoute;
            }
          });
        } while (currentRoute);
        return breadcrumbs;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };
}

/**
 * Log to the console each value
 * @param label Description text for reference
 */
export function debug<T>(label: string) {
  return tap({
    next: (value: T) => {
      // eslint-disable-next-line no-console
      console.log(
        `%c[${label}: Next]`,
        "background: #009688; color: #fff; padding: 3px; font-size: 9px;",
        value
      );
    },
    error: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.log(
        `%[${label}: Error]`,
        "background: #E91E63; color: #fff; padding: 3px; font-size: 9px;",
        error
      );
    },
    complete: () => {
      // eslint-disable-next-line no-console
      console.log(
        `%c[${label}]: Complete`,
        "background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;"
      );
    }
  });
}

/**
 * Get the root activate route recursively
 * @param route Provided activate route
 */
export const rootRoute = (route: ActivatedRoute): ActivatedRoute => {
  while (route.firstChild) {
    route = route.firstChild;
  }
  return route;
};

/**
 * Gets the root primary activated route on navigation end
 * @param route The activated route
 */
export function primaryActivatedRoute(route: ActivatedRoute) {
  return pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => rootRoute(route)),
    filter((route: ActivatedRoute) => route.outlet === "primary")
  );
}

/**
 * Get the data object from the path of the activate route
 * @param activatedRoute The activated route
 */
export function activatedRouteData(activatedRoute: ActivatedRoute): any {
  return pipe(
    mapActivatedRoute(activatedRoute),
    map((activatedRoute: ActivatedRoute) => rootRoute(activatedRoute)),
    switchMap((firstChild: ActivatedRoute) => firstChild.data)
  );
}

/**
 * Create a path from a route starting from root
 * @param route a snapshot of the route
 * @returns array of route commands
 */
export const getPathFromRoute = (route: ActivatedRouteSnapshot): string[] =>
  route.pathFromRoot
    .map(s => s.url)
    // flatMap equivlant
    .reduce((a: UrlSegment[], b: UrlSegment[]) => a.concat(b), [])
    .map((s: UrlSegment) => s.path);

/***
 * Merge additional queryParams if not already existing on queryParams
 */
export const addQueryParams = (params: Params, add: Params) => {
  return { ...add, ...params };
};
