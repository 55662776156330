import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AnswerGroup, CaseData } from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

/** @todo rename to RecordSchema */
@Injectable({
  providedIn: "root"
})
export class AnswerGroupApiService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private http: HttpClient) {}

  updateRecordData(caseId: string, recordData: Record<string, unknown>): Observable<CaseData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/recorddata`;
    return this.http.put<CaseData>(apiURL, recordData);
  }

  saveManagementData(
    caseId: string,
    managementData: Record<string, unknown>
  ): Observable<CaseData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/management`;
    return this.http.put<CaseData>(apiURL, managementData);
  }

  /** @deprecated use updateRecordData */
  public updateAnswerGroup = (
    caseId: string,
    questionGroupId: string,
    answerGroup: AnswerGroup
  ): Observable<boolean> => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/answergroup/${questionGroupId}`;
    return this.http
      .put(apiURL, answerGroup, {
        observe: "response"
      })
      .pipe(
        map((result: HttpResponse<any>) => {
          if (result.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  /** @deprecated use updateManagementData */
  public updateCoordinationAnswerGroup(caseId: string, answerGroup: AnswerGroup) {
    let apiURL = `${this.apiBaseUrl}/case/${caseId}/coordination/`;
    if (answerGroup.answerGroupId) {
      apiURL = apiURL + `${answerGroup.answerGroupId}`;
    }

    const questionGroupId = answerGroup.questionGroupId;
    const answers = answerGroup.answers;
    const answerGroupId = answerGroup.answerGroupId;

    return this.http
      .put(apiURL, { questionGroupId, answers, answerGroupId }, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  }
}
