import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/material/form-field";

@Component({
  selector: "lib-formly-intl-phone-input-type",
  templateUrl: "./formly-intl-phone-input-type.component.html",
  styleUrls: ["./formly-intl-phone-input-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyIntlPhoneInputTypeComponent extends FieldType {
  formControl!: FormControl;
  constructor() {
    super();
  }
}
