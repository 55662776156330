import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/material";
import { NgxLinkifyjsService } from "ngx-linkifyjs";

@Component({
  selector: "lib-formly-json-schema-form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputComponent extends FieldType implements OnInit {
  formControl!: FormControl;
  url: string | undefined;

  constructor(public linkifyService: NgxLinkifyjsService) {
    super();
  }

  ngOnInit(): void {
    this.extractURL();
  }

  extractURL() {
    if (this.field.model && this.field.key && this.field.model.hasOwnProperty(this.field.key)) {
      const theValue = this.field.model[this.field.key as string];
      if (theValue) {
        const isUrl = this.linkifyService.find(theValue);
        if (isUrl && isUrl.length > 0 && isUrl[0].type === "url") {
          this.url = isUrl[0].href;
        }
      }
    }
  }
}
