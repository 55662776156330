import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { Group } from "@vp/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as GroupsActions from "./state+/groups-actions";
import { GroupsState } from "./state+/groups-state";

@Injectable()
export class GroupsDataResolver implements Resolve<Group[]> {
  constructor(private store: Store) {}

  resolve(): Group[] | Observable<Group[]> | Promise<Group[]> {
    return this.store
      .dispatch(new GroupsActions.LoadGroups())
      .pipe(map(() => this.store.selectSnapshot(GroupsState)));
  }
}
