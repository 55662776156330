import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { CaseType } from "@vp/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as CaseTypesActions from "./state+/case-types-actions";
import { CaseTypesState } from "./state+/case-types-state";

@Injectable()
export class CaseTypesDataResolver implements Resolve<CaseType[]> {
  constructor(private store: Store) {}

  resolve(): CaseType[] | Observable<CaseType[]> | Promise<CaseType[]> {
    return this.store
      .dispatch(new CaseTypesActions.LoadCaseTypes())
      .pipe(map(() => this.store.selectSnapshot(CaseTypesState)));
  }
}
