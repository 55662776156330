import { EventBase } from "@vp/shared/event-aggregator";

export class NotificationEvent extends EventBase<NotificationEventData> {
  constructor(private data: NotificationEventData) {
    super();
    this.args = this.data;
  }
}

export interface NotificationEventData {
  type: NotificationEventType;
  title: string;
  message: string;
  error: any;
}

declare type NotificationEventType = "success" | "error" | "info" | "warning";
