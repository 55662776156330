<mat-form-field fxFlex>
  <mat-label>{{ to.label }}</mat-label>
  <input
    matInput
    [ngxMatDatetimePicker]="picker"
    placeholder="{{ to.label }}"
    [formControl]="formControl"
    [min]="minDate"
    [max]="maxDate"
    [disabled]="disabled"
  />

  <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker
    #picker
    [showSpinners]="to.showSpinners"
    [showSeconds]="to.showSeconds"
    [stepHour]="to.stepHour"
    [stepMinute]="to.stepMinute"
    [stepSecond]="to.stepSecond"
    [touchUi]="to.touchUi"
    [color]="to.color"
    [enableMeridian]="to.enableMeridian"
    [disableMinute]="to.disableMinute"
    [hideTime]="to.hideTime"
  >
    <ng-template>
      <span>OK</span>
    </ng-template>
  </ngx-mat-datetime-picker>
</mat-form-field>
