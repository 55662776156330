import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { FieldArrayType, FormlyFieldConfig, FormlyTemplateOptions } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-table-layout-type",
  templateUrl: "./formly-table-layout-type.component.html",
  styleUrls: ["./formly-table-layout-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTableLayoutTypeComponent extends FieldArrayType implements OnInit {
  dataSource: undefined | MatTableDataSource<FormlyFieldConfig>;
  columnDefs: undefined | FormlyFieldConfig[];
  columnKeys: undefined | string[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    // Set the data from fieldGroup
    this.dataSource = new MatTableDataSource(this.arrayData());

    // Set column defs from fieldArray
    this.columnDefs = this.arrayFields();

    // Set the column keys from the defs
    this.columnKeys = this.columnDefs?.map(col => col.key as string);
  }

  add() {
    super.add();
    if (this.dataSource) {
      this.dataSource.data = this.arrayData() as FormlyFieldConfig[];
    }
  }

  remove(dataIndex: number): void {
    super.remove(dataIndex); // removes from fieldGroup array
    if (this.dataSource) {
      this.dataSource.data = this.arrayData() as FormlyFieldConfig[]; // copy to table
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  trackByKey(index: number, item: any) {
    return item.key ?? index;
  }

  showActions() {
    this.columnKeys = this.columnKeys?.filter(item => item !== "actions");
    if (!this.to.disabled) {
      this.columnKeys?.push("actions");
    }
  }

  // template-no-call-expression     Avoid calling expressions in templates
  removeTooltip = getRemoveButtonTooltipPureFn;
  getColumns = getColumnsPureFn;
  getField = getFieldPureFn;
  addTooltip = getAddButtonTooltipPureFn;

  private arrayData(): undefined | FormlyFieldConfig[] {
    return this.field.fieldGroup;
  }

  private arrayFields(): undefined | FormlyFieldConfig[] {
    return this.field.fieldArray?.fieldGroup;
  }

  asString = (value: any) => {
    return value as string;
  };
}

export function getRemoveButtonTooltipPureFn(dataIndex: number, model: any) {
  let tooltip = "Remove ";
  if (model && model.length) {
    const data = Object.values(model[dataIndex] || {});
    tooltip = tooltip + data.toString().substring(0, 20) + "...";
  }
  return tooltip;
}

export function getColumnsPureFn(columnKeys: string[], disabled: boolean): string[] {
  columnKeys = columnKeys?.filter(item => item !== "actions");
  if (!disabled) {
    columnKeys?.push("actions");
  }
  return columnKeys;
}

export function getFieldPureFn(
  field: FormlyFieldConfig,
  column: FormlyFieldConfig,
  dataIndex: number
): undefined | FormlyFieldConfig {
  const fg = field.fieldGroup && field.fieldGroup[dataIndex];
  return fg?.fieldGroup?.find(f => f.key === column.key);
}

export function getAddButtonTooltipPureFn(to: FormlyTemplateOptions) {
  return to?.label ? `Add ${to.label}` : "Add item";
}
