<div
  class="tel-input-container"
  [formGroup]="formGroup"
  [attr.aria-labelledby]="_formField?.getLabelId()"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
>
  <div formGroupName="phone">
    <div fxFlex="50">
      <input
        matInput
        title="title"
        type="tel"
        [placeholder]="phoneHint"
        (input)="formatNumber()"
        formControlName="number"
      />
      <mat-error *ngIf="!disabled && !formGroup.valid">{{ phoneHint }}</mat-error>
    </div>
    <div fxFlex="50">
      <mat-select formControlName="countryCode" (selectionChange)="formatNumber()">
        <mat-option *ngFor="let countryCode of countyCodes" [value]="countryCode.code">
          {{ countryCode.country }}
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>
