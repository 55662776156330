import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { CaseStatusCount, CommunicationSummary } from "@vp/models";
import { Observable } from "rxjs";

export const CASE_SUMMARY_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class CaseSummaryService {
  constructor(
    @Inject(CASE_SUMMARY_API_BASE_URL) private _apiBaseUrl: string,
    private readonly _http: HttpClient
  ) {}

  getCaseStatusCountsForCurrentUser = (): Observable<CaseStatusCount[]> => {
    const apiURL = `${this._apiBaseUrl}/casesummary?filter=active`;
    return this._http.get<CaseStatusCount[]>(apiURL);
  };

  getCommunicationSummary(): Observable<CommunicationSummary> {
    const apiURL = `${this._apiBaseUrl}/case/message/summary`;
    return this._http.get<CommunicationSummary>(apiURL);
  }
}
