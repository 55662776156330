import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef
} from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { defaultDisplayTags } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { Subject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { activatedRouteData } from "@vp/shared/utilities";
import { map, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "vp-top-nav-search",
  templateUrl: "./top-nav-search.component.html",
  styleUrls: ["./top-nav-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavSearchComponent implements OnInit {
  @Input() showButtonText = true;
  quickSearchTag$: Observable<keyof typeof defaultDisplayTags> | undefined;
  inputOriginal = "";
  input = "";
  focused = false;
  @ViewChild("searchinput", { static: false }) searchinput!: ElementRef;

  private searchRoute!: string[];

  constructor(
    public uiDisplayTagService: UiDisplayTagService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public readonly authenticationService: AuthenticationService
  ) {}
  private readonly destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.quickSearchTag$ = this.router.events.pipe(
      activatedRouteData(this.activatedRoute),
      tap((data: Data) => {
        this.searchRoute = data.searchRoute ? [data.searchRoute] : [];
      }),
      map((data: Data) => data.quickSearchTag as keyof typeof defaultDisplayTags)
    );

    this.activatedRoute.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: queryParamMap => {
        this.inputOriginal = queryParamMap.get("search") ?? "";
        this.input = queryParamMap.get("search") ?? "";
      }
    });
  }

  searchClicked() {
    this.redirect(this.input || "");
  }

  enterPress() {
    this.redirect(this.input);
  }

  clear() {
    // User wants to clear input, but do not perform search yet
    this.input = "";
    this.focused = true;
  }

  private redirect(searchString: string) {
    if (searchString !== "") {
      this.router.navigate(this.searchRoute, {
        queryParams: {
          search: searchString
        },
        queryParamsHandling: "merge"
      });
    } else {
      this.router.navigate(this.searchRoute, {});
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
