import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/material/form-field";

@Component({
  selector: "lib-formly-textarea-type",
  templateUrl: "./formly-textarea-type.component.html",
  styleUrls: ["./formly-textarea-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTextareaTypeComponent extends FieldType {
  formControl!: FormControl;
  constructor() {
    super();
  }
}
