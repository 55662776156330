<div *ngIf="commands && commands.length > 0" fxLayout="row wrap" fxLayoutGap="10px">
  <button
    *ngFor="let command of commands"
    type="button"
    class="btn-command"
    mat-stroked-button
    color="primary"
    matTooltip="{{ command.description }}"
    (click)="sendAdvancedCommand(command)"
  >
    {{ command.displayName }}
  </button>
</div>
<div *ngIf="commands?.length === 0">No Commands Are Configured</div>
