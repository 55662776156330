<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    items: (items$ | async) || [],
    columns: (columns$ | async) || [],
    search: (search$ | async) || null,
    selected: (selected$ | async) || [],
    departments: (departments$ | async) || []
  }"
>
</ng-container>

<ng-template
  #template
  let-items="items"
  let-columns="columns"
  let-search="search"
  let-selected="selected"
  let-departments="departments"
>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
    <div fxFlex="noshrink" mat-dialog-title>Assign Departments & Roles</div>
    <mat-form-field>
      <mat-label>Departments</mat-label>
      <mat-select
        [formControl]="departmentSelector"
        [multiple]="false"
        #departments
        (selectionChange)="departmentsFilterChanged($event.value)"
      >
        <mat-option *ngFor="let dept of departments" [value]="dept">{{
          dept | department | async
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <vp-quick-search
      fxFlex="50"
      (changedEvent)="searched($event)"
      aria-placeholder="Filter"
    ></vp-quick-search>
  </div>

  <mat-dialog-content fxLayout="column">
    <mat-selection-list
      fxFill
      fxLayout="column"
      fxLayoutGap="10px"
      #selectionList
      [multiple]="!singleDepartmentAssignment || !singleRoleAssignment"
      (selectionChange)="selectionChanged($event)"
      class="selection"
    >
      <ng-container *ngIf="items | filterTerm: search:excludeProperties:filters as filtered">
        <mat-list-option
          *ngFor="let item of filtered; trackBy: trackById"
          [value]="item"
          [selected]="isSelected(item) | async"
        >
          <div class="list-row" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="40">{{ item.departmentId | department | async }}</div>
            <div fxFlex="40">{{ item.roleId | role | async }}</div>
          </div>
        </mat-list-option>
        <mat-list-item *ngIf="filtered.length === 0">No Assignable Roles</mat-list-item>
      </ng-container>
    </mat-selection-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div fxFlex fxLayout="row" fxLayoutAlign="end">
      <button mat-flat-button [mat-dialog-close] type="button">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="selected"
        [disabled]="selected.length === 0"
        (click)="assignSelected()"
        type="button"
      >
        Assign
      </button>
    </div>
  </mat-dialog-actions>
</ng-template>
