<mat-form-field>
  <button
    *ngIf="inputText.length > 0"
    matPrefix
    mat-icon-button
    (click)="clear()"
    type="button"
    matTooltip="Reset"
  >
    <mat-icon color="accent" aria-hidden="false" class="icon" inline="true">close</mat-icon>
  </button>
  <mat-label>Search</mat-label>
  <input
    title="Search Input"
    id="searchInput"
    #searchinput
    matInput
    [placeholder]="inputPlaceholder"
    (keyup.enter)="enterPress()"
    [(ngModel)]="inputText"
    (ngModelChange)="changed()"
    type="search"
    autofocus
  />
  <button
    id="submitButton"
    matSuffix
    type="submit"
    mat-icon-button
    disableRipple
    matTooltip="Perform search or [ENTER]"
    (click)="searchClicked()"
  >
    <mat-icon
      aria-hidden="false"
      class="icon"
      inline="true"
      [color]="focused ? 'accent' : 'primary'"
      >search
    </mat-icon>
  </button>
</mat-form-field>
