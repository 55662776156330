<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    assigned: (assignedRoles$ | async) || [],
    columns: (displayedColumns$ | async) || []
  }"
>
</ng-container>

<ng-template #template let-assigned="assigned" let-columns="columns">
  <div class="border-wrapper">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>Assigned Roles</mat-panel-title>
        <mat-panel-description>
          <span>Assigned: {{ assigned.length }}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="assigned.length > 0">
        <mat-table #assignedTable [dataSource]="assigned">
          <ng-container *ngFor="let column of columns; let i = index">
            <ng-container
              *ngIf="column.field !== 'actions'; else actions"
              [matColumnDef]="column.field"
            >
              <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
              <mat-cell *matCellDef="let item">
                <span *ngIf="!column.pipe; else piped">{{ item[column.field] }}</span>
                <ng-template #piped>
                  <span *ngIf="column.pipe">{{
                    item[column.field] | dynamicLookup: column.pipe:item[column.field] | async
                  }}</span>
                </ng-template>
              </mat-cell>
            </ng-container>
            <ng-template #actions>
              <ng-container matColumnDef="actions" stickyEnd="true">
                <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center"
                  >Delete</mat-header-cell
                >
                <mat-cell *matCellDef="let assignedRole" fxLayoutAlign="end center">
                  <button
                    type="button"
                    mat-icon-button
                    aria-hidden="false"
                    aria-label="Delete forever icon"
                    (click)="unassignHandler(assignedRole)"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
            </ng-template>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns | mapToField: 'field'"></mat-header-row>
          <mat-row *matRowDef="let tag; columns: columns | mapToField: 'field'"></mat-row>
        </mat-table>
      </div>
      <mat-action-row>
        <button
          *ngxPermissionsOnly="[permConst.Admin.User.RoleAssignment.Write]"
          type="button"
          mat-flat-button
          color="primary"
          (click)="assignHandler()"
        >
          Assign Role(s)
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #modalTemplate>
  <vp-user-assign-roles></vp-user-assign-roles>
</ng-template>
