import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { Organization } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "tagType"
})
export class TagTypePipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  public transform(tagTypeId: string): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      map(org => {
        const found = org.tagTypes.find(r => r.tagTypeId === tagTypeId);
        return `${found?.displayName ?? "[tag type  not found]"}`;
      })
    );
  }
}
