<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    breadcrumbs: (breadcrumbs | async) || []
  }"
>
</ng-container>

<ng-template #template let-breadcrumbs="breadcrumbs">
  <div fxLayout="row" fxLayoutAlign="start center" fxShow fxHide.lt-md>
    <ul>
      <li *ngFor="let crumb of breadcrumbs; trackBy: trackByFn" [routerLink]="crumb.url">
        {{ uiDisplayTagService.displayTags[crumb.label] || crumb.label }}
      </li>
    </ul>
  </div>
</ng-template>
