import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  CommunicationData,
  CommunicationSummary,
  CommunicationUpdate,
  CreateCommunication
} from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CommunicationsApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private readonly http: HttpClient
  ) {}

  public getMessages(caseId: string, messageId: string): Observable<CommunicationData[]> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/message/${messageId}`;
    return this.http.get<CommunicationData[]>(apiURL);
  }

  public createCommunication(
    caseId: string,
    com: CreateCommunication
  ): Observable<CommunicationData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/message`;
    return this.http.post<CommunicationData>(apiURL, com);
  }

  public updateCommunication(caseId: string, com: CommunicationUpdate) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/message/${com.communicationId}`;
    return this.http.put<CommunicationData>(apiURL, com);
  }

  public markResolved(caseId: string, communicationId: string, isResolved: boolean) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/message/${communicationId}/resolved/${isResolved}`;
    return this.http.put<CommunicationData>(apiURL, null);
  }

  public getCommunicationSummary(): Observable<CommunicationSummary> {
    const apiURL = `${this.apiBaseUrl}/case/message/summary`;
    return this.http.get<CommunicationSummary>(apiURL);
  }

  public getCommunication(caseId: string, communicationId: string): Observable<CommunicationData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/message/${communicationId}`;
    return this.http.get<CommunicationData>(apiURL);
  }
}
