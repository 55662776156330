import { EventBase } from "@vp/shared/event-aggregator";

export interface PatientMessageNotification {
  caseId: string;
  message: string;
  messageSentBy: string;
  messageDateTime: string;
}

export class MessageToPatientEvent extends EventBase<PatientMessageNotification> {
  constructor(patientMessageNotifcation: PatientMessageNotification) {
    super();
    this.args = patientMessageNotifcation;
  }
}
