import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// TODO What is the following comment block from? The `enableProductionMode` is not available?

/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */
export enum LogLevel {
  Off = 0,
  Debug,
  Info,
  Warning,
  Error
}

// TODO Is this an Application Insights concept? Is it fully implemented? If so, how?
// TODO Add parameter to optionally disable/enable logging to console for better dev exp.
// TODO Perhaps the static "outputs" can be used to do this, but that is not evident yet.
export type LogOutput = (source: string | undefined, level: LogLevel, ...objects: any[]) => void;

@Injectable({
  providedIn: "root"
})
export class Logger {
  static level = LogLevel.Debug;
  static outputs: LogOutput[] = [];
  appInsights: ApplicationInsights;

  constructor(@Inject("environment") private environment: any) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }

  // TODO Not sure if there is a better way to enable logging to console
  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.environment.production) {
      this.appInsights.trackEvent({ name }, properties);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `%c[logEvent]`,
        "background: #ee90ee; color: #fff; padding: 3px; font-size: 9px;",
        name,
        properties ? properties : ""
      );
    }
  }

  logException(exception: Error, areaOfApp?: string) {
    if (this.environment.production) {
      this.appInsights.trackException({ exception, severityLevel: LogLevel.Error });
    } else {
      console.error(exception, areaOfApp ? areaOfApp : "");
    }
  }

  logHttpException(error: HttpErrorResponse, severityLevel?: number, endpoint?: string) {
    if (this.environment.production) {
      this.appInsights.trackException({ exception: error, severityLevel });
    } else {
      console.error(error, severityLevel ? severityLevel : "", endpoint ? endpoint : "");
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.environment.production) {
      this.appInsights.trackMetric({ name, average }, properties);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `%c[logMetric]`,
        "background: #ee90ee; color: #fff; padding: 3px; font-size: 9px;",
        name,
        average,
        properties ? properties : ""
      );
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.environment.production) {
      this.appInsights.trackTrace({ message }, properties);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `%c[logTrace]`,
        "background: #ee90ee; color: #fff; padding: 3px; font-size: 9px;",
        message,
        properties ? properties : ""
      );
    }
  }
}
