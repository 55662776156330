import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "lib-time-picker-type",
  templateUrl: "./time-picker-type.component.html",
  styleUrls: ["./time-picker-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerTypeComponent extends FieldType implements AfterViewInit, OnDestroy {
  formControl!: FormControl;

  private readonly destroyed = new Subject();

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed),
        tap((formValues: Record<string, unknown>) => {
          if (
            Object.prototype.hasOwnProperty.call(this.to, "defaultValueProperty") &&
            Object.prototype.hasOwnProperty.call(formValues, this.to["defaultValueProperty"])
          ) {
            const date = formValues[this.to["defaultValueProperty"]];
            this.formControl.setValue(date, { emitEvent: false });
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  timeChangeHandler(time: Date) {
    this.formControl.setValue(time);
  }
}
