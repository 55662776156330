import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { Tag } from "@vp/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as TagsActions from "./state+/tags-actions";
import { TagsState } from "./state+/tags-state";

@Injectable()
export class TagsResolver implements Resolve<Tag[]> {
  constructor(private store: Store) {}

  resolve(): Tag[] | Observable<Tag[]> | Promise<Tag[]> {
    return this.store
      .dispatch(new TagsActions.LoadTags())
      .pipe(map(() => this.store.selectSnapshot(TagsState)));
  }
}
