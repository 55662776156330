import { Guid } from "guid-typescript";
import { Subject } from "rxjs";

export interface IEvent {
  handled: Subject<boolean>;
  eventTime: Date;
  eventId: Guid;
  sender: string;
  args: any;
}

export class EventBase<TArgs> implements IEvent {
  readonly eventId: Guid;
  readonly eventTime: Date;
  readonly handled!: Subject<boolean>;

  sender!: string;
  args!: TArgs;

  constructor() {
    this.eventTime = new Date();
    this.eventId = Guid.create();
    this.handled = new Subject<boolean>();
  }
}
