import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { FormlyModule } from "@ngx-formly/core";
import { NgxsModule } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { DynamicTemplateModule } from "@vp/shared/directives/dynamic-template";
import { UtilitiesModule } from "@vp/shared/utilities";
import { TagSelectorComponent } from "./tag-selector.component";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    NgxsModule.forFeature([TagsState]),
    DynamicTemplateModule,
    UtilitiesModule,
    FormlyModule.forChild({
      types: [
        {
          name: "tag-selector",
          component: TagSelectorComponent
        }
      ]
    })
  ],
  declarations: [TagSelectorComponent],
  exports: [TagSelectorComponent]
})
export class TagSelectorModule {}
