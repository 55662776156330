<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    items: (items$ | async) || [],
    columns: (columns$ | async) || [],
    search: (search$ | async) || null,
    selected: (selected$ | async) || [],
    assignableGroupTypes: (assignableGroupTypes$ | async) || []
  }"
>
</ng-container>

<ng-template
  #template
  let-items="items"
  let-columns="columns"
  let-search="search"
  let-selected="selected"
  let-assignableGroupTypes="assignableGroupTypes"
>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
    <div fxFlex="noshrink" mat-dialog-title>Assign Groups</div>

    <mat-form-field>
      <mat-label>Group Types</mat-label>
      <mat-select
        [formControl]="groupTypeSelector"
        [multiple]="false"
        #departments
        (selectionChange)="groupTypeFilterChanged($event.value)"
      >
        <mat-option
          *ngFor="let groupType of assignableGroupTypes"
          [value]="groupType.groupTypeId"
          >{{ groupType.displayName }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <vp-quick-search
      fxFlex="50"
      (changedEvent)="searched($event)"
      aria-placeholder="Filter"
    ></vp-quick-search>
  </div>

  <mat-dialog-content fxLayout="column" class="selection">
    <mat-selection-list
      fxFill
      fxLayout="column"
      fxLayoutGap="8px"
      #selectionList
      (selectionChange)="selectionChanged($event)"
    >
      <ng-container *ngIf="items | filterTerm: search:excludeProperties:filters as filtered">
        <mat-list-option
          *ngFor="let item of filtered; trackBy: trackById"
          [value]="item"
          [selected]="isSelected(item) | async"
        >
          <div class="list-row" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="40">{{ item.displayName }}</div>
            <div fxFlex="40">{{ item.description }}</div>
          </div>
        </mat-list-option>
        <mat-list-item *ngIf="filtered.length === 0">No Assignable Groups</mat-list-item>
      </ng-container>
    </mat-selection-list>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="space-between center">
    <div class="flex-fix" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="selected">
        <span *ngIf="hasSelected$ | async">Selected Groups: {{ selectedCount$ | async }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="end">
        <button mat-flat-button [mat-dialog-close] type="button">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          [mat-dialog-close]="selectionList.selectedOptions.selected"
          [disabled]="selectionList.selectedOptions.isEmpty()"
          (click)="assignSelected()"
          type="button"
        >
          Assign
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</ng-template>
