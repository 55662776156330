/**
 * These are the Global permissions.
 */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export const globalPermissions = {
  FeaturePreview: {
    View: "globalFeaturePreviewCanView"
  },
  AboutPage: {
    View: "globalAboutPageCanView"
  },
  AdministrationPage: {
    View: "globalAdministrationPageCanView"
  },
  CaseSearchPage: {
    View: "globalCaseSearchPageCanView"
  },
  CaseQueuePage: {
    View: "globalCaseQueuePageCanView"
  },
  SubjectSearchPage: {
    View: "globalSubjectSearchPageCanView"
  },
  DashboardPage: {
    View: "globalDashboardPageCanView"
  },
  DeviceManagerPage: {
    View: "globalDeviceManagerPageCanView"
  },
  PatientManagerPage: {
    View: "globalPatientManagerPageCanView"
  },
  ProfileDetailsPage: {
    View: "globalProfileDetailsPageCanView"
  },
  AdminTagsPage: {
    View: "globalAdminTagsPageCanView"
  },
  ContentManagementPage: {
    View: "globalContentManagementPageCanView"
  }
};
