import { Inject, Injectable, Optional } from "@angular/core";
import { Params } from "@angular/router";
import { Role } from "@vp/models";
import { UTILITY_OPTIONS } from "./tokens";

export interface RouterUtilityOptions {
  routeDefault: string;
}

@Injectable({
  providedIn: "root"
})
export class RouterUtilities {
  constructor(@Optional() @Inject(UTILITY_OPTIONS) private options: RouterUtilityOptions) {}

  static parseQueryString(search: string): Record<string, string> {
    return (search || "")
      .replace(/^\?/g, "")
      .split("&")
      .reduce((acc, query) => {
        const [key, value] = query.split("=");
        if (key) {
          acc[key] = decodeURIComponent(value);
        }
        return acc;
      }, {} as Record<string, string>);
  }

  public getRouteDefaultFromRole(role: Role) {
    let path = this.options.routeDefault;
    let queryParams: Params = {};
    if (role.routeDefault && role.routeDefault.path) {
      path = role.routeDefault.path as string;
    }
    if (role.routeDefault && role.routeDefault.queryParamData) {
      queryParams = role.routeDefault.queryParamData.reduce(
        (obj: Params, item: { key: string; value: string }) => ((obj[item.key] = item.value), obj),
        {}
      ) as Params;
    }
    return { path, queryParams };
  }
}
