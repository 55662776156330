<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    assignedGroups: (assignedGroups$ | async) ?? [],
    columns: columns || []
  }"
>
</ng-container>

<ng-template #template let-assignedGroups="assignedGroups" let-columns="columns">
  <div class="border-wrapper">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="panel-header"
        ><mat-panel-title>Assigned Groups</mat-panel-title>
        <mat-panel-description>
          <span>Assigned: {{ assignedGroups.length }}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div *ngIf="assignedGroups.length > 0">
        <mat-table #assignedTable [dataSource]="assignedGroups">
          <ng-container *ngFor="let column of columns; let i = index">
            <ng-container
              *ngIf="column.field !== 'actions'; else actions"
              [matColumnDef]="column.field"
            >
              <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
              <mat-cell *matCellDef="let item">
                <span *ngIf="!column.pipe; else piped">{{ item[column.field] }}</span>
                <ng-template #piped>
                  <span *ngIf="column.pipe">{{
                    item[column.field] | dynamicLookup: column.pipe:item[column.field] | async
                  }}</span>
                </ng-template>
              </mat-cell>
            </ng-container>
            <ng-template #actions>
              <ng-container matColumnDef="actions" stickyEnd="true">
                <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center">
                  {{ column.header }}
                </mat-header-cell>
                <mat-cell *matCellDef="let item" fxLayoutAlign="end center">
                  <button
                    type="button"
                    mat-icon-button
                    aria-hidden="false"
                    aria-label="Delete"
                    (click)="unassignHandler(item)"
                  >
                    <mat-icon> delete_forever </mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
            </ng-template>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns | mapToField: 'field'"></mat-header-row>
          <mat-row *matRowDef="let tag; columns: columns | mapToField: 'field'"></mat-row>
        </mat-table>
      </div>
      <mat-action-row>
        <button
          *ngxPermissionsOnly="[permConst.Admin.User.GroupAssignment.Write]"
          type="button"
          mat-flat-button
          color="primary"
          (click)="assignHandler()"
        >
          Assign Groups(s)
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #modalTemplate>
  <vp-user-assign-groups></vp-user-assign-groups>
</ng-template>
