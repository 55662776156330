import { CommonModule } from "@angular/common";
import { InjectionToken, NgModule } from "@angular/core";
import { CaseTypesDataResolver } from "./cast-types-data-resolver";

export const CASE_TYPES_API_BASE_URL = new InjectionToken<string>("CASE_TYPES_API_BASE_URL");

@NgModule({
  imports: [CommonModule],
  providers: [CaseTypesDataResolver]
})
export class CaseTypesDataAccessModule {}
