<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    form: (form$ | async) || null,
    legalNoticeRequested: (legalNoticeRequested$ | async) || false
  }"
>
</ng-container>

<ng-template #template let-form="form" let-legalNoticeRequested="legalNoticeRequested">
  <div fxLayout="row" fxLayoutAlign="center">
    <mat-card
      *ngIf="form && legalNoticeRequested"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="50"
    >
      <form [formGroup]="form">
        <h1 lang="en" class="primary">Welcome to our Second Opinion Portal</h1>
        <h1 lang="es" class="primary">Bienvenidos al Portal “Second Opinion”</h1>
        <p lang="en" class="primary">
          We are very happy to be able to provide you with this service. Before we can provide you
          with full access, we ask that you acknowledge you have read, understand, and agree to
          abide by our terms and conditions by checking both of the checkboxes below and then
          clicking Accept and Continue:
        </p>
        <p lang="es" class="primary">
          Nos complace poder ofrecerle este servicio. Antes de que podamos proporcionarle un acceso
          completo, le pedimos que reconozca que ha leído, entendido y aceptado cumplir con nuestros
          términos y condiciones marcando las dos casillas de verificación que aparecen a
          continuación y haciendo clic en Aceptar y Continuar (Accept and Continue):
        </p>
        <p lang="en" class="primary">
          If you have any questions about this Second Opinion Portal or the above documents, please
          contact your DHI Case Manager.
        </p>
        <p lang="es" class="primary">
          Si tiene alguna pregunta acerca del Portal “Second Opinion” o sobre los documentos
          mencionados, póngase en contacto con su Administrador de Casos Clínicos de DHI.
        </p>
        <mat-accordion>
          <mat-expansion-panel [expanded]="isExpanded('tos') | async">
            <mat-expansion-panel-header
              fxLayout="row"
              fxLayoutAlign="start center"
              (click)="toggleOpened('tos')"
            >
              <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                Terms of Service/Términos de Servicio
                <mat-icon
                  *ngIf="isAccepted('tos') | async"
                  aria-hidden="false"
                  class="mat-step-done"
                  class="done"
                >
                  done
                </mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <section id="tos">
              <h2 lang="en">Online Terms of Service</h2>
              <h2 lang="es">Términos y Condiciones del Servicio en Línea</h2>
              <p lang="es">Última actualización:</p>
              <p lang="en">
                These Online Terms of Service (“Terms”) apply to your use of the service you are
                currently using and the technology supporting the service, all of which are provided
                by or behalf of us (collectively the “Online Services”). We (“we,” “us,” or “our”)
                make the Online Services available to you (sometimes referred to as “user,” or
                “your”) in exchange for your agreement with all of the terms and conditions below.
              </p>
              <p lang="es">
                Estos Términos y Condiciones del Servicio en Línea (los “Términos y Condiciones”) se
                aplican a su uso actual de este servicio y a la tecnología que respalda dicho
                servicio, los cuales son proporcionados por nosotros o en nuestro nombre
                (denominados, en forma conjunta, “Servicios en Línea”). Nosotros (“nosotros” o
                “nuestro”) ponemos los Servicios en Línea a su disposición (en adelante, “usted” o
                “el usuario”) a cambio de su aceptación de todos los términos y condiciones a
                continuación.
              </p>
              <p lang="en">
                THIS IS A BINDING CONTRACT BETWEEN YOU AND US. PLEASE READ THESE TERMS CAREFULLY.
                IMPORTANT NOTICE: YOUR USE OF THE ONLINE SERVICES IS SUBJECT TO AN ARBITRATION
                PROVISION OUTLINED BELOW, REQUIRING ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING
                ARBITRATION.
              </p>
              <p lang="es">
                ESTE ES UN CONTRATO VINCULANTE ENTRE USTED Y NOSOTROS. LEA LOS TÉRMINOS Y
                CONDICIONES CON DETENIMIENTO. AVISO IMPORTANTE: SU USO DE LOS SERVICIOS EN LÍNEA
                ESTÁ SUJETO A UNA PROVISIÓN DE ARBITRAJE DESCRITA A CONTINUACIÓN, LA CUAL EXIGE QUE
                TODOS LOS RECLAMOS SEAN RESUELTOS MEDIANTE ARBITRAJE.
              </p>
              <h2 lang="en">1. Acceptance of Terms</h2>
              <h2 lang="es">Aceptación de los Términos y Condiciones</h2>
              <p lang="en">
                By accessing or using the Online Services in any manner, YOU ACCEPT THESE TERMS.
                THESE TERMS INCLUDE ALL INFORMATION, NOTICES, AND POLICIES INCLUDING ANY
                SUPPLEMENTAL TERMS THAT ARE HYPERLINKED WITHIN THE ONLINE SERVICES OR OTHERWISE
                APPLICABLE TO A PARTICULAR PRODUCT OR SERVICE. IF YOU DO NOT AGREE TO THESE TERMS,
                DO NOT ACCESS OR USE THE ONLINE SERVICES. Your access or use of the Online Services
                binds you to the Terms. In the event of termination of any Online Service, you agree
                to continue to be bound by the obligations set forth under the Terms.
              </p>
              <p lang="es">
                Al acceder o usar los Servicios en Línea de cualquier manera, USTED ESTÁ ACEPTANDO
                ESTOS TÉRMINOS Y CONDICIONES. ESTOS TÉRMINOS Y CONDICIONES INCLUYEN CUALQUIER
                INFORMACIÓN, AVISO Y POLÍTICA, INCLUIDOS LOS TÉRMINOS Y CONDICIONES ADICIONALES, QUE
                FIGURAN CON UN HIPERVÍNCULO DENTRO DE LOS SERVICIOS EN LÍNEA O QUE APLICAN DE OTRO
                MODO A UN PRODUCTO O SERVICIO EN PARTICULAR. SI USTED NO ACEPTA ESTOS TÉRMINOS Y
                CONDICIONES, NO PUEDE ACCEDER NI USAR LOS SERVICIOS EN LÍNEA. Si usted accede o usa
                los Servicios en Línea, quedará sujeto a estos Términos y Condiciones. En caso de
                finalización de cualquiera de los Servicios en Línea, usted acepta permanecer sujeto
                a las obligaciones establecidas en los Términos y Condiciones.
              </p>
              <h2 lang="en">2. No Medical Advice</h2>
              <h2 lang="es">No constituye asesoramiento médico</h2>
              <p lang="en">
                WE DO NOT MAKE ANY GUARANTEE ABOUT THE RELATIONSHIP BETWEEN YOU AND US OR ANY HEALTH
                CARE PROVIDER, OR WHETHER ANY INFORMATION, PRODUCTS, OR SERVICES YOU FIND ON THE
                ONLINE SERVICES WILL HELP YOU ACHIEVE THE RESULT(S) YOU WANT.
              </p>
              <p lang="es">
                NO PROPORCIONAMOS GARANTÍAS RESPECTO DE LA RELACIÓN ENTRE USTED Y NOSOTROS O CON UN
                PROVEEDOR DE ATENCIÓN MÉDICA, NI GARANTIZAMOS QUE CUALQUIER INFORMACIÓN, PRODUCTO O
                SERVICIO ENCONTRADO EN LOS SERVICIOS EN LÍNEA LO AYUDARÁN A ALCANZAR LOS RESULTADOS
                DESEADOS.
              </p>
              <h2 lang="en">3. Your Right to Access the Online Services</h2>
              <h2 lang="es">Su derecho a acceder a los Servicios en Línea</h2>
              <p lang="en">
                We grant you a limited, revocable, non-transferable, and non-exclusive right to
                access and use the Online Services for your personal use only. We may terminate, in
                our sole discretion, your right to use the Online Services if you use the Online
                Services in excess of this limited right. We do not guarantee the accuracy of the
                Online Services. We retain the right to revoke this right or disable or deny access
                to our Online Services to any user for any reason.
              </p>
              <p lang="es">
                Le otorgamos el derecho limitado, revocable, intransferible y no exclusivo de
                acceder y usar los Servicios en Línea para uso personal únicamente. Podemos anular
                su derecho a usar los Servicios en Línea, a nuestro exclusivo criterio, si su uso de
                dichos Servicios en Línea excede este derecho limitado. No garantizamos la precisión
                de los Servicios en Línea. Nos reservamos el derecho de revocar este derecho o
                inhabilitar o denegar el acceso a nuestros Servicios en Línea a cualquier usuario
                por cualquier razón.
              </p>
              <h2 lang="en">4. Our Content</h2>
              <h2 lang="es">Nuestro Contenido</h2>
              <p lang="en">
                We strive to post accurate and up-to-date information, content, applications, and
                materials on the Online Services (“Our Content”). We grant you a limited, revocable,
                non-transferable right to use Our Content for your personal use. Our Content is
                either (1) authored or produced by our staff or physicians, or (2) licensed from
                outside sources. We do not guarantee the accuracy of Our Content.
              </p>
              <p lang="es">
                Nos esforzamos por publicar contenido, aplicaciones, materiales e información
                precisa y actualizada en los Servicios en Línea (“Nuestro Contenido”). Le otorgamos
                el derecho limitado, revocable e intransferible de usar Nuestro Contenido para uso
                personal. Nuestro Contenido es (1) generado o creado por nuestro personal o nuestros
                médicos o (2) licenciado de fuentes externas. No garantizamos la precisión de
                Nuestro Contenido.
              </p>
              <p lang="en">
                We retain all ownership rights, control, or licenses to Our Content. Except as
                expressly permitted by these Terms, you and any other individual(s) are expressly
                prohibited from reproducing, duplicating, copying, publishing, selling or otherwise
                exploiting Our Content. We may terminate, in our sole discretion, your right to use
                Our Content for any reason we deem appropriate.
              </p>
              <p lang="en">
                Retenemos todos los derechos de propiedad, control o licencia de Nuestro Contenido.
                Excepto por lo permitido expresamente por estos Términos y Condiciones, se prohíbe
                expresamente a usted y a cualquier otra persona la reproducción, duplicación, copia,
                publicación, venta o explotación de cualquier otro tipo de Nuestro Contenido.
                Podemos anular su derecho a usar Nuestro Contenido por cualquier razón que
                consideremos apropiada, a nuestro exclusivo criterio.
              </p>
              <h2 lang="en">5. Our Intellectual Property Rights</h2>
              <h2 lang="es">Nuestros Derechos de Propiedad Intelectual</h2>
              <p lang="en">
                The “look and feel” of the Online Services and Our Content including, text,
                graphics, logos, button icons, photos, images, forms, audio, video, questionnaires,
                and software, is our property or our licensors’ property (“Our IP”). Our IP is
                subject to all applicable copyright, patent, trademark, and various other
                intellectual property laws under United States law, foreign laws, and international
                conventions. Except as provided in the next paragraph, you are expressly prohibited
                from reproducing, duplicating, copying, publishing, selling or otherwise exploiting
                for any commercial purpose Our IP, in whole or in part, for any reason, unless prior
                written consent is obtained from the copyright holder(s). Copyright 1996 to 2021.
                All rights reserved for all countries.
              </p>
              <p lang="es">
                La apariencia de los Servicios en Línea y Nuestro Contenido, incluidos el texto,
                gráficos, logos, íconos de botones, fotografías, imágenes, formularios, audios,
                videos, cuestionarios y software, son de nuestra propiedad y la de nuestros
                licenciatarios (“Nuestra PI” protocolo de internet). Nuestra PI está sujeta a todas
                las leyes aplicables de derechos de autor, patentes, marcas comerciales y otras
                leyes de propiedad intelectual de los Estados Unidos, legislación extranjera y
                convenciones internacionales. Con excepción de lo dispuesto en el próximo párrafo,
                se prohíbe expresamente la reproducción, duplicación, copia, publicación, venta o
                explotación de cualquier otro tipo con fines comerciales de Nuestra PI, ya sea en su
                totalidad o en parte, por cualquier motivo, a menos que se obtenga consentimiento
                por escrito del titular o los titulares del derecho de autor. Derechos de autor 1996
                a 2021. Todos los derechos reservados para todos los países.
              </p>
              <p lang="en">
                We grant you a limited, revocable, non-transferable license to view and use Our IP
                for your personal use only, provided that such use of Our IP is limited to within
                the Online Services, without the right to print, except you may print these Terms
                for your personal use to review the Terms. Any automated process or use of a bot for
                this purpose is strictly prohibited and the limited license is not granted for such
                use. Unless specifically authorized in writing by us, any other use of Our IP is
                expressly prohibited. Any rights not expressly granted by these terms and conditions
                or any applicable end-user license agreements are reserved by us.
              </p>
              <p lang="es">
                Le otorgamos una licencia limitada, revocable e intransferible para visualizar y
                usar Nuestra PI para uso personal únicamente, siempre que use Nuestra PI
                exclusivamente dentro de los Servicios en Línea, sin derecho de impresión, salvo el
                derecho a imprimir estos Términos y Condiciones para su uso personal. Se prohíbe
                estrictamente el procesamiento automatizado o uso de un bot para este fin y la
                licencia limitada no se otorga para ese uso. A menos que nosotros lo autoricemos
                específicamente por escrito, cualquier otro uso de Nuestra PI queda expresamente
                prohibido. Todos los derechos que no están expresamente otorgados en estos términos
                y condiciones o cualquier Acuerdo de Licencia de usuario final aplicable están
                reservados para nosotros.
              </p>
              <h2 lang="en">6. Your Account</h2>
              <h2 lang="es">Su Cuenta</h2>
              <p lang="en">
                If you create an account within the Online Services, you are the only person
                authorized to access and use your account (“Your Account”). You will not give your
                username, password, or other account information to any other individual(s). You
                must immediately notify us of any suspected or known unauthorized use of Your
                Account or any potential security incident, including, an unauthorized disclosure of
                your password. YOU ARE RESPONSIBLE FOR ALL ACTIVITY ON YOUR ACCOUNT. You agree that
                it is a privilege to create Your Account and that we may revoke or deactivate Your
                Account, upon our sole discretion, at any time without notice.
              </p>
              <p lang="es">
                Si crea una cuenta en los Servicios en línea, usted es la única persona autorizada
                para acceder y utilizar su cuenta ("Su Cuenta"). No dará su nombre de usuario,
                contraseña u otra información de la cuenta a ninguna otra persona. Debe notificarnos
                inmediatamente cualquier sospecha o conocimiento de uso no autorizado de su cuenta o
                cualquier posible incidente de seguridad, incluida la divulgación no autorizada de
                su contraseña. USTED ES RESPONSABLE DE TODA LA ACTIVIDAD DE SU CUENTA. Usted acepta
                que la creación de su cuenta es un privilegio y que podemos revocar o desactivar su
                cuenta, a nuestra entera discreción, en cualquier momento y sin previo aviso.
              </p>
              <p lang="en">
                To promote your interests and improve your overall experience with the Online
                Services, you may submit content to us, your healthcare provider, or a caregiver by
                posting messages, creating or modifying a home page, chatting, uploading files,
                inputting data, or transmitting e-mail through Your Account. When you submit content
                to us, you provide us and our service providers an unrestricted, perpetual,
                worldwide license to use your content in any manner. We may translate, adapt,
                communicate, publish, publicly perform, publicly display, transmit and distribute
                your content. You warrant and represent you have the necessary rights to grant us
                and our service providers a license to your content. This license continues even if
                you stop using the Online Services.
              </p>
              <p lang="es">
                Para promover sus intereses y mejorar su experiencia general con los Servicios en
                línea, puede enviarnos contenido a nosotros, a su proveedor de atención médica o a
                un cuidador, publicando mensajes, creando o modificando una página de inicio,
                chateando, cargando archivos, introduciendo datos o transmitiendo correo electrónico
                a través de su cuenta. Cuando nos envía contenido, nos proporciona a nosotros y a
                nuestros proveedores de servicios una licencia mundial, perpetua y sin restricciones
                para utilizar su contenido de cualquier manera. Podemos traducir, adaptar,
                comunicar, publicar, ejecutar públicamente, mostrar públicamente, transmitir y
                distribuir su contenido. Usted garantiza y declara que tiene los derechos necesarios
                para concedernos a nosotros y a nuestros proveedores de servicios una licencia sobre
                su contenido. Esta licencia continúa incluso si usted deja de utilizar los Servicios
                en línea.
              </p>
              <h2 lang="en">7. Your Suggestions</h2>
              <h2 lang="es">Sus Sugerencias</h2>
              <p lang="en">
                If you elect to make available or provide suggestions, comments, ideas,
                improvements, or other information or materials to us (“Your Suggestions”), in any
                manner, in connection with or related to the Online Services, you grant us a
                non-exclusive, perpetual, royalty-free, irrevocable right to use, reproduce,
                duplicate, copy, publish, sell or otherwise exploit for any commercial purpose Your
                Suggestions. You agree that we will not compensate you for any use of Your
                Suggestions.
              </p>
              <p lang="es">
                Si usted elige ofrecernos o enviarnos sugerencias, comentarios, ideas, mejoras u
                otra información o materiales (“Sus Sugerencias”), de cualquier manera, en relación
                con los Servicios en Línea, usted nos otorga un derecho irrevocable, perpetuo, no
                exclusivo, sin regalías para usar, reproducir, duplicar, copiar, publicar, vender o
                explotar de cualquier otro modo Sus Sugerencias con fines comerciales. Usted acepta
                que no le compensaremos por el uso de sus sugerencias.
              </p>
              <h2 lang="en">8. Prohibited Use</h2>
              <h2 lang="es">Usos prohibidos</h2>
              <p lang="en">You agree that you will not use the Online Services to:</p>
              <p lang="es">Usted acepta no usar los Servicios en Línea para:</p>
              <ol>
                <li>
                  <p lang="en">
                    Delete, hack, or attempt to change or alter Our Content, Our IP, or notices;
                  </p>
                  <p lang="es">
                    Borrar, hackear o intentar cambiar o alterar Nuestro Contenido, Nuestra PI o
                    avisos;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Use any device, software to damage or interfere with the Online Services,
                    servers, or networks connected to the Online Services;
                  </p>
                  <p lang="es">
                    Usar un dispositivo o software para dañar o interferir los Servicios en Línea,
                    los servidores o las redes conectadas a los Servicios en Línea;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Use any automatic or manual device or process to harvest or compile information
                    from the Online Services;
                  </p>
                  <p lang="es">
                    Usar cualquier proceso o dispositivo automático o manual para extraer o compilar
                    información de los Servicios en Línea;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Copy, modify, create derivative works, reverse engineer, decompile, or
                    disassemble the source code upon which the Online Services are based;
                  </p>
                  <p lang="es">
                    Copiar, modificar, crear obras derivadas, manipular usando ingeniería inversa,
                    descompilar o desagrupar el código fuente sobre el cual se basan los Servicios
                    en Línea;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Introduce any malicious software, viruses, bugs, or malware into the Online
                    Services designed to harm the Online Services;
                  </p>
                  <p lang="es">
                    Insertar software malicioso, virus, errores o malware dentro de los Servicios en
                    Línea diseñado para dañar los servicios en línea;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Use Our Content or Our IP in any manner that misappropriates or infringes the
                    Intellectual Property rights of another individual;
                  </p>
                  <p lang="es">
                    Usar Nuestro Contenido o Nuestra PI de cualquier manera que pueda malversar o
                    infringir los derechos de propiedad intelectual de otra persona o entidad;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Attempt to gain unauthorized access to another individual’s personal
                    information, non-personal information, or PHI;
                  </p>
                  <p lang="es">
                    Intentar obtener acceso no autorizado a la información personal, información no
                    personal o Información médica protegida (PHI) de un individuo;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Post, upload, or transmit photos or videos of another individual without
                    express, without written permission from the individual;
                  </p>
                  <p lang="es">
                    Publicar, cargar o transmitir fotografías o videos de otra persona sin su
                    permiso expreso y escrito;
                  </p>
                </li>
                <li>
                  <p lang="en">Send junk mail, unauthorized e-mail, or advertisements;</p>
                  <p lang="es">
                    Enviar correo electrónico no deseado, no autorizado o publicidades;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Encourage any illegal activities or post obscene, defamatory, threatening,
                    abusive, or hateful content directed at another individual; and
                  </p>
                  <p lang="es">
                    Alentar actividades ilícitas o publicar contenido obsceno, difamatorio,
                    amenazante, abusivo o de odio dirigido hacia otra persona; y
                  </p>
                </li>
                <li>
                  <p lang="en">Violate any applicable laws or regulations.</p>
                  <p lang="es">Violar cualquier ley o reglamentación aplicable.</p>
                </li>
              </ol>
              <h2 lang="en">9. Deactivation of Your Account</h2>
              <h2 lang="es">Desactivación de Su Cuenta</h2>
              <p lang="en">
                As mentioned above in Section 6 (Your Account), to maintain security and privacy of
                the Online Services, we may, in our sole discretion, modify, suspend, terminate, or
                close Your Account, or stop providing you access to the Online Services, for any
                reason, including, the use of Your Account in any fraudulent, abusive, or other
                illegal manner as well as committing any prohibited use listed above in Section 8
                (Prohibited Use).
              </p>
              <p lang="es">
                Como se menciona en la Sección 6 (Su Cuenta), para mantener la seguridad y la
                privacidad de los Servicios en Línea, podemos, a nuestro exclusivo criterio,
                modificar, suspender, anular o cerrar Su Cuenta, o dejar de brindarle acceso a los
                Servicios en Línea, por cualquier motivo, incluido usar Su Cuenta de manera
                fraudulenta, abusiva o ilegal o cometer cualquiera de los usos prohibidos enumerados
                en la Sección 8 (Usos prohibidos).
              </p>
              <p lang="en">
                We will not be liable to you or any other individual for the deactivation of Your
                Account or the suspension of the Online Services.
                <strong
                  >You are responsible for making sure your information is properly backed
                  up.</strong
                >
                We do not export any content or information during your use of the Online Services,
                or provide any content or information to you when we close Your Account.
              </p>
              <p lang="es">
                No seremos responsables ante usted ni ninguna otra persona por la desactivación de
                Su Cuenta o la suspensión de los Servicios en Línea. Usted es responsable de
                asegurarse de contar con una copia de respaldo de su información. No exportamos el
                contenido ni la información durante el uso de los Servicios en Línea ni le
                brindaremos ningún contenido ni información cuando cerremos Su Cuenta.
              </p>
              <h2 lang="en">10. Disclaimer of Warranties</h2>
              <h2 lang="es">Renuncia a las Garantías</h2>
              <p lang="en">
                WE PROVIDE THE ONLINE SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, AND PRODUCTS
                (INCLUDING SOFTWARE) MADE AVAILABLE TO YOU THROUGH THE ONLINE SERVICES (INCLUDING
                HYPERLINKED WEBSITES), ON AN "AS IS" AND "AS AVAILABLE" BASIS. NEITHER WE NOR ANY
                THIRD PARTY, INCLUDING OUR SERVICE PROVIDERS, MAKE ANY REPRESENTATIONS OR WARRANTIES
                OF ANY KIND, EITHER EXPRESS OR IMPLIED, AS TO THE OPERATION OF OR THE INFORMATION,
                CONTENT, MATERIALS, AND PRODUCTS MADE AVAILABLE TO YOU THROUGH THE ONLINE SERVICES.
              </p>
              <p lang="es">
                LE PROPORCIONAMOS LOS SERVICIOS EN LÍNEA Y CUALQUIER OTRA INFORMACIÓN, CONTENIDO,
                MATERIALES Y PRODUCTOS (INCLUIDO SOFTWARE) A TRAVÉS DE LOS SERVICIOS EN LÍNEA
                (INCLUIDOS LOS HIPERVÍNCULOS A OTROS SITIOS), EN LAS CONDICIONES EN QUE SE
                ENCUENTRAN Y EN LA MEDIDA EN QUE ESTÉN DISPONIBLES. NI NOSOTROS NI NINGÚN TERCERO,
                INCLUIDOS NUESTROS PROVEEDORES DE SERVICIOS, OFRECEMOS GARANTÍAS DE NINGÚN TIPO, NI
                EXPRESAS NI IMPLÍCITAS, RESPECTO DE LA OPERACIÓN DE LOS SERVICIOS EN LÍNEA O DE LA
                INFORMACIÓN, EL CONTENIDO, LOS MATERIALES Y PRODUCTOS PUESTOS A SU DISPOSICIÓN A
                TRAVÉS DE LOS SERVICIOS EN LÍNEA.
              </p>
              <p lang="en">
                TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES,
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NO ORAL OR
                WRITTEN INFORMATION OR ADVICE CONTAINED IN THE ONLINE SERVICES SHALL CREATE A
                WARRANTY NOR INCREASE THE SCOPE OF THIS WARRANTY.
              </p>
              <p lang="es">
                EN EL MÁXIMO ALCANCE PERMITIDO POR LA LEY APLICABLE, DENEGAMOS TODAS LAS GARANTÍAS,
                EXPRESAS O IMPLÍCITAS, INCLUIDAS, ENTRE OTRAS, LA GARANTÍA IMPLÍCITA DE
                COMERCIALIZACIÓN, LA GARANTÍA DE APTITUD PARA UN FIN DETERMINADO Y LA GARANTÍA DE NO
                VIOLACIÓN DE DERECHOS DE TERCEROS. NINGUNA INFORMACIÓN O ASESORAMIENTO, ORAL O
                ESCRITO, CONTENIDO EN LOS SERVICIOS EN LÍNEA CREARÁ UNA GARANTÍA NI AUMENTARÁ EL
                ALCANCE DE ESTA GARANTÍA.
              </p>
              <p lang="en">
                CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF THESE LAWS
                APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS SHALL
                BE REFORMED TO THE MAXIMUM LIMITATION PERMITTED BY APPLICABLE LAW.
              </p>
              <p lang="es">
                ALGUNAS LEYES ESTATALES NO PERMITEN LA LIMITACIÓN DE LAS GARANTÍAS IMPLÍCITAS. SI
                ESTAS LEYES SE APLICAN A USTED, ALGUNAS O TODAS LAS RENUNCIAS, EXCLUSIONES O
                LIMITACIONES ANTERIORES SE REFORMARÁN HASTA LA MÁXIMA LIMITACIÓN PERMITIDA POR LA
                LEY APLICABLE.
              </p>
              <h2 lang="en">11. Other Organizations’ Websites</h2>
              <h2 lang="es">Sitios web de otras organizaciones</h2>
              <p lang="en">
                We make no representations whatsoever about any other website that you may access
                through the Online Services. The Online Services may include hyperlinks to other
                organizations’ websites with information about their products and services. We have
                no control over such websites, and we do not endorse nor are we responsible for any
                content or information available on those websites.
                <strong
                  >WE ENCOURAGE YOU TO READ AND ASK QUESTIONS OF OTHER ORGANIZATIONS BEFORE YOU
                  PROVIDE THEM YOUR INFORMATION.</strong
                >
              </p>
              <p lang="es">
                No ofrecemos ningún tipo de garantía sobre ningún sitio web al que pueda acceder
                mediante los Servicios en Línea. Los Servicios en Línea pueden incluir hipervínculos
                a sitios web de otras organizaciones con información acerca de sus productos y
                servicios. No tenemos ningún control sobre dichos sitios web y no avalamos ni somos
                responsables por el contenido o la información disponible en esos sitios.
                <strong
                  >LE RECOMENDAMOS LEER Y CONSULTAR A LAS OTRAS ORGANIZACIONES ANTES DE
                  PROPORCIONARLES SU INFORMACIÓN.</strong
                >
              </p>
              <h2 lang="en">12. Limitation of Liability</h2>
              <h2 lang="es">Limitación de la responsabilidad</h2>
              <p lang="en">THIS SECTION LIMITS OUR LIABILITY TO YOU.</p>
              <p lang="es">ESTA SECCIÓN LIMITA NUESTRA RESPONSABILIDAD ANTE USTED.</p>
              <p lang="en">
                IN NO EVENT AND UNDER NO CIRCUMSTANCES WHATSOEVER SHALL WE OR ANY OF OUR SPONSORS,
                MEMBERS, AFFILIATES, SUBSIDIARIES, EMPLOYEES, CONTRACTORS, AGENTS, REPRESENTATIVES,
                SUCCESSORS OR ASSIGNS OR ANY OTHER RELATED PERSONS OR ENTITIES BE LIABLE FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER
                DAMAGES (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST
                DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR RELATING IN ANY WAY TO THE ONLINE
                SERVICES, ITS RELATED SERVICES AND PRODUCTS, CONTENT CONTAINED WITHIN THE ONLINE
                SERVICES AND/OR ANY HYPERLINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE ONLINE SERVICES, ITS RELATED
                SERVICES AND/OR HYPERLINKED WEBSITES IS TO STOP USING THE ONLINE SERVICES AND/OR
                THOSE SERVICES.
              </p>
              <p lang="es">
                EN NINGÚN CASO, NOSOTROS, NI NINGUNO DE NUESTROS PATROCINADORES, MIEMBROS, FILIALES,
                SUBSIDIARIAS, EMPLEADOS, CONTRATISTAS, AGENTES, REPRESENTANTES, SUCESORES O
                CESIONARIOS NI NINGUNA OTRA PERSONA O ENTIDAD RELACIONADA, SEREMOS RESPONSABLES POR
                DAÑOS DIRECTOS, INDIRECTOS, INCIDENTALES, CONSECUENTES, ESPECIALES,
                EJEMPLIFICATORIOS, PUNITIVOS O DE OTRO TIPO (INCLUIDOS, ENTRE OTROS, LOS DAÑOS QUE
                RESULTEN DE LUCRO CESANTE, PÉRDIDA DE DATOS O INTERRUPCIONES DEL NEGOCIO), QUE
                SURJAN O ESTÉN RELACIONADOS DE CUALQUIER MODO CON LOS SERVICIOS EN LÍNEA, SUS
                SERVICIOS Y PRODUCTOS RELACIONADOS, EL CONTENIDO DENTRO DE LOS SERVICIOS EN LÍNEA O
                LOS SITIOS WEB VINCULADOS, YA SEA EN BASE A UNA GARANTÍA, UN CONTRATO, UN ILÍCITO
                CIVIL O CUALQUIER OTRA TEORÍA LEGAL Y AUNQUE LA POSIBILIDAD DE QUE OCURRAN DICHOS
                DAÑOS HAYA SIDO ANTICIPADA. SU ÚNICO RECURSO EN CASO DE INSATISFACCIÓN CON LOS
                SERVICIOS EN LÍNEA, SUS SERVICIOS Y PRODUCTOS RELACIONADOS O LOS SITIOS WEB
                VINCULADOS, ES DEJAR DE USARLOS.
              </p>
              <p lang="en">
                CERTAIN STATE LAWS DO NOT ALLOW FOR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS SHALL
                BE REFORMED TO THE MAXIMUM LIMITATION PERMITTED BY APPLICABLE LAW.
              </p>
              <p lang="es">
                CIERTAS LEYES ESTATALES NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS. SI
                ESAS LEYES APLICAN A USTED, ALGUNAS O TODAS LAS EXCLUSIONES O LIMITACIONES DEBERÁN
                SER MODIFICADAS A LA LIMITACIÓN MÁXIMA PERMITIDA POR LA LEY APLICABLE.
              </p>
              <h2 lang="en">13. Indemnity</h2>
              <h2 lang="es">Indemnidad</h2>
              <p lang="en">
                You agree to defend, indemnify, and hold us, our officers, members, directors,
                employees, contractors, and agents (<strong>“Indemnified Party”</strong> or
                collectively <strong>“Indemnified Parties”</strong>), from and against any and all
                claims, actions, losses or expenses, including attorneys’ fees and costs and
                expenses, arising out of or in any way connected with (a) your access to or use of
                all or part of the Online Services; (b) any personal information you provide to the
                Online Services; (c) an alleged or actual breach by you of these Terms; (d) your
                infringement or misappropriation of our IP; or (e) any negligence or willful
                misconduct by you.
              </p>
              <p lang="es">
                Usted acepta defender, indemnizar y mantener indemne a la empresa, nuestros
                funcionarios, miembros, directores, empleados, contratistas y agentes (la
                <strong>“Parte Indemnizada”</strong> o, en forma conjunta, las
                <strong>“Partes Indemnizadas”</strong>), contra cualquier reclamo, acción judicial,
                pérdida o gasto, que surja o se relacione de cualquier modo con (a) su acceso o uso
                de todo o parte de los Servicios en Línea; (b) cualquier información personal
                proporcionada a los Servicios en Línea; (c) un incumplimiento, real o sospechado,
                por su parte de estos Términos y Condiciones; (d) su infracción o malversación de
                Nuestra PI; o (e) cualquier acto negligente o doloso de su parte.
              </p>
              <p lang="en">
                You will defend the Indemnified Parties, at your expense, from any claim, liability,
                damage, or loss or expense, referenced above; provided, however, you will not
                obligate the Indemnified Parties in any respect in connection with any settlement
                without the prior written consent of an Indemnified Party. Si usted no asume su
                obligación de defensa, las Partes Indemnizadas podrán hacerlo para proteger sus
                intereses y usted reembolsará todos los costes en los que incurra una Parte
                Indemnizada en relación con dicha defensa, incluidos los honorarios de los abogados
                y los costes y gastos.
              </p>
              <p lang="es">
                Usted defenderá a las Partes Indemnizadas, a su propio cargo, contra cualquiera de
                los gastos, responsabilidad, daños y perjuicios, pérdidas o gastos, mencionados
                anteriormente; estableciéndose, sin embargo, que usted no obligará de ningún modo a
                las Partes Indemnizadas en relación con ninguna conciliación sin el consentimiento
                escrito previo de una de las Partes Indemnizadas. Si usted no asume su obligación de
                defender, las Partes Indemnizadas pueden hacerlo para proteger sus intereses y usted
                deberá reembolsarles todos los costos incurridos en relación con dicha defensa,
                incluidos los honorarios de abogados y las costas legales.
              </p>
              <h2 lang="en">14. Choice of Law</h2>
              <h2 lang="es">Legislación aplicable</h2>
              <p lang="en">
                These Terms are governed by the laws of the State of California, USA, exclusive of
                its choice of law rules.
              </p>
              <p lang="es">
                Estos Términos y Condiciones están regidos por las leyes del Estado de California,
                Estados Unidos, según las normas relativas a elección de fuero.
              </p>
              <h2 lang="en">15. Dispute Resolution</h2>
              <h2 lang="es">Resolución de disputas</h2>
              <p lang="en"><strong>1. Arbitration</strong></p>
              <p lang="es"><strong>Arbitraje</strong></p>
              <p lang="en">
                You agree that any dispute, action, or claim relating in any manner to these Terms
                or the Online Services will be resolved, individually and not in a class action, by
                binding arbitration in San Francisco County, California pursuant to the Commercial
                Arbitration Rules of the American Arbitration Association (“AAA”) and as applicable
                with the assistance of the AAA’s International Centre for Dispute Resolution. The
                arbitrator, and not any federal, state, or local court or agency, shall have
                exclusive authority to resolve any dispute relating to the interpretation,
                applicability, enforceability, or formation of these Terms, including any claim that
                all or any part, of these Terms is void or voidable. Notwithstanding the foregoing,
                either party may seek any interim or preliminary relief from a court of competent
                jurisdiction in San Francisco County, California as necessary to protect the party’s
                rights or property.
              </p>
              <p lang="es">
                Usted acepta que cualquier disputa, acción judicial o reclamo relacionado de
                cualquier modo con estos Términos y Condiciones o los Servicios en Línea será
                resuelta, de manera individual y no como demanda colectiva, mediante arbitraje
                vinculante en el Condado de San Francisco, California, de conformidad con las Reglas
                de Arbitraje Comercial de la Asociación Estadounidense de Arbitraje (“AAA”) y, según
                corresponda, con asistencia del Centro Internacional para Resolución de Disputas de
                la AAA. El árbitro, y no una agencia ni un tribunal federal, estatal o local, tendrá
                la facultad exclusiva de resolver cualquier disputa con relación a la
                interpretación, aplicación, ejecución o formación de estos Términos y Condiciones,
                incluido cualquier reclamo de que parte o la totalidad de estos Términos y
                Condiciones son nulos o anulables. Sin perjuicio de lo anterior, cualquiera de las
                partes puede intentar obtener una medida cautelar o preliminar de un tribunal con
                competencia del Condado de San Francisco, California, según sea necesario para
                proteger sus derechos o propiedad.
              </p>
              <p lang="en"><strong>2. Class Action Waiver</strong></p>
              <p lang="es"><strong>Renuncia al derecho a la demanda colectiva</strong></p>
              <p lang="en">
                A class action, consolidated action, or representative action is a legal proceeding
                brought by a group of people, with an identical claim, who are represented together
                by a member of the group.
              </p>
              <p lang="es">
                Una demanda colectiva, una acción consolidada o acción representativa es un proceso
                legal iniciado por un grupo de personas con idénticos reclamos, que están
                representadas en conjunto por un miembro del grupo.
              </p>
              <p lang="en">
                By agreeing to these Terms, you agree that any dispute, action, or claim will be
                resolved on an individual basis and not in a class action, consolidated action, or
                representative action. If, for any reason, any dispute, action, or claim proceeds in
                court rather than in arbitration, you agree to only bring your claim on an
                individual basis and not as part of a class action, consolidated action, or
                representative action. You agree to waive any rights that you may have to bring a
                claim in a class action, consolidated action, or representative action.
              </p>
              <p lang="es">
                Al aceptar estos Términos y Condiciones, usted acepta que cualquier disputa, acción
                judicial o reclamo será resuelto de manera individual y no mediante una demanda
                colectiva, una acción consolidada o una acción representativa. Si, por cualquier
                motivo, alguna de las disputas, acciones judiciales o reclamos se tramita en un
                tribunal, en lugar de resolverse en arbitraje, usted acepta iniciar únicamente su
                reclamo de manera individual y no como parte de una demanda colectiva, una acción
                consolidada o una acción representativa. Usted acepta renunciar a cualquier derecho
                que pudiera tener a iniciar un reclamo en una demanda colectiva, una acción
                consolidada o una acción representativa.
              </p>
              <h2 lang="en">16. Miscellaneous</h2>
              <h2 lang="es">Misceláneos</h2>
              <p lang="en"><strong>1. Changes to these Terms</strong></p>
              <p lang="es"><strong>Cambios a estos Términos y Condiciones</strong></p>
              <p lang="en">
                We may revise and update the Terms and will post the updated Terms to the Online
                Services. Unless otherwise stated in the amended version of the Terms, any changes
                to the Terms will apply immediately upon posting. If we make, in our sole
                discretion, material changes to the Terms, we may provide you notice of the updated
                Terms. Your use of the Online Services will constitute your agreement to the updated
                Terms.
              </p>
              <p lang="es">
                Podemos revisar y actualizar los Términos y Condiciones; de hacerlo, publicaremos
                los Términos y Condiciones actualizados en los Servicios en Línea. A menos que se
                establezca lo contrario en la versión modificada de los Términos y Condiciones,
                cualquier cambio a esos Términos y Condiciones regirá de inmediato luego de la
                publicación. Si, a nuestro exclusivo criterio, realizamos cambios esenciales a los
                Términos y Condiciones, es posible que enviemos una notificación con los Términos y
                Condiciones actualizados. Su uso de los Servicios en Línea constituirá su aceptación
                de los Términos y Condiciones actualizados.
              </p>
              <p lang="en"><strong>2. Severability</strong></p>
              <p lang="es"><strong>Divisibilidad</strong></p>
              <p lang="en">
                If any portion of these Terms are held unenforceable, the unenforceable portion
                shall be construed to the greatest extent possible under applicable law. Our failure
                to insist upon or enforce strict performance of any provision of these Terms shall
                not be construed as a waiver of any provision or right.
              </p>
              <p lang="es">
                Si cualquier parte de estos Términos y Condiciones se considera inexigible, la parte
                inexigible será interpretada en la máxima medida posible en virtud de la ley
                aplicable. No insistir ni exigir el cumplimiento estricto de alguna disposición de
                estos Términos y Condiciones no deberá interpretarse como una renuncia de esa
                disposición o derecho.
              </p>
              <p lang="en"><strong>3. No Waivers</strong></p>
              <p lang="es"><strong>Sin renuncias</strong></p>
              <p lang="en">
                Our failure to enforce or exercise our rights under or provision of the Terms, does
                not constitute a waiver of that right or provision.
              </p>
              <p lang="es">
                No exigir ni ejercitar nuestros derechos en virtud de alguna disposición de estos
                Términos y Condiciones no constituye una renuncia a esa disposición o derecho.
              </p>
              <p lang="en"><strong>4. No Assignments and Transfers</strong></p>
              <p lang="es"><strong>Sin cesiones ni transferencias</strong></p>
              <p lang="en">
                You may not assign or transfer any rights provided to you under these Terms to a
                third party, without our prior written consent which we may withhold, in our sole
                discretion, for any reason.
              </p>
              <p lang="es">
                Usted no puede ceder ni transferir a terceros ninguno de los derechos en virtud de
                estos Términos y Condiciones, sin consentimiento escrito previo, el cual podemos
                retener, a nuestro exclusivo criterio, por cualquier motivo.
              </p>
              <p lang="en"><strong>5. No Third-Party Beneficiaries</strong></p>
              <p><strong>Sin terceros beneficiarios</strong></p>
              <p lang="en">
                Nothing in these Terms will confer upon any third party, not a party to these Terms,
                any rights, remedies, obligations, or liabilities whatsoever.
              </p>
              <p lang="es">
                Ninguna parte de estos Términos y Condiciones le conferirá a ningún tercero, que no
                sea parte de estos Términos y Condiciones, ningún derecho, recurso, obligación ni
                responsabilidad de ningún tipo.
              </p>
              <p lang="en"><strong>6. Entire Agreement</strong></p>
              <p lang="es"><strong>Acuerdo completo</strong></p>
              <p lang="en">
                These Terms constitute the entire agreement between you and us relating to your use
                of Online Services and supersedes any prior or contemporaneous agreements or
                representations. The Terms may not be amended except as set forth in Section 16.1
                (Changes to these Terms).
              </p>
              <p lang="es">
                Estos Términos y Condiciones constituyen el acuerdo completo entre usted y nosotros
                con relación a su uso de los Servicios en Línea y reemplaza cualquier acuerdo o
                declaración previa o contemporánea. Los Términos y Condiciones no pueden ser
                modificados, salvo de la manera establecida en la Sección 16.1 (Cambios a estos
                Términos y Condiciones).
              </p>
              <p lang="en"><strong>7. Notices</strong></p>
              <p lang="es"><strong>Notificaciones</strong></p>
              <p lang="en">
                You may send us any questions, concerns, or relevant notices to the contact
                information provided below in Section 16.8 (Contact Us). You agree to receive all
                communications from us by e-mail. You are responsible for providing us with
                up-to-date contact information, which you may do by updating Your Account or sending
                a message to us. You agree that all communications that we send to you
                electronically satisfy any legal requirement that a communication be in writing. You
                may print the communications for your records.
              </p>
              <p lang="es">
                Usted puede enviarnos preguntas, inquietudes o notificaciones relevantes a la
                información de contacto provista a continuación en la Sección 16.8 (Contáctenos).
                Usted acepta recibir todas las comunicaciones mediante correo electrónico. Usted es
                responsable de brindarnos información de contacto actualizada; usted puede
                actualizar su información de contacto desde Su Cuenta o enviándonos un mensaje.
                Usted acepta que todas las comunicaciones enviadas por nosotros de manera
                electrónica cumplirán con todos los requisitos legales de las notificaciones
                escritas. Puede imprimir las comunicaciones para su registro.
              </p>
              <p lang="en"><strong>8. Contact Us</strong></p>
              <p lang="es"><strong>Contáctenos</strong></p>
              <p lang="en">If you have any questions about these Terms, please contact us at:</p>
              <p lang="es">
                Si tiene preguntas acerca de estos Términos y Condiciones, contáctenos a
              </p>
              <p fxLayout="row" fxLayoutAlign="end">
                <mat-checkbox formControlName="tos" (click)="accepted('tos')"
                  >I accept/Acepto</mat-checkbox
                >
              </p>
            </section>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="isExpanded('supplemental') | async">
            <mat-expansion-panel-header
              fxLayout="row"
              fxLayoutAlign="start center"
              (click)="toggleOpened('tos')"
            >
              <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                Supplemental Terms of Service
                <mat-icon
                  *ngIf="isAccepted('supplemental') | async"
                  aria-hidden="false"
                  class="mat-step-done"
                  class="done"
                >
                  done
                </mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <section id="supplemental">
              <h1 lang="en">Supplemental Terms - Second Opinion Portal</h1>
              <h1 lang="es">Términos y Condiciones adicionales - Portal “Second Opinion”</h1>
              <h3 lang="en">Last Updated: 6/7/2022</h3>
              <h3 lang="es">Última actualización: 6/7/2022</h3>
              <p lang="en">
                PLEASE CAREFULLY READ THESE SUPPLEMENTAL TERMS - SECOND OPINION WEBSITE (hereinafter
                referred to as the “Supplemental Terms”). This website and the services provided
                through it (collectively, the "Portal Services") is provided by Dignity Health
                International, LLC, and its affiliates, including third party software vendor,
                service providers and physicians (collectively referred to as “DHI,” “us,” “our,” or
                “we”).
              </p>
              <p lang="es">
                LEA CON DETENIMIENTO ESTOS TÉRMINOS Y CONDICIONES ADICIONALES DEL SITIO WEB “SECOND
                OPINION” (denominados en adelante los “Términos y Condiciones Adicionales”). El
                sitio web y los servicios provistos a través de este (en forma conjunta, los
                “Servicios del Portal”) se ofrecen a través de Dignity Health International, LLC, y
                sus filiales, incluidos los médicos, proveedores de servicios y proveedores de
                software de terceros (denominados, en forma conjunta, “DHI”, “nosotros” o
                “nuestro”).
              </p>
              <p lang="en">
                By accessing or using the Portal Services you agree to be bound by these
                Supplemental Terms. These Supplemental Terms constitute a binding contract between
                you (sometimes referred to as “you” or “your” or “your family”) and DHI.
              </p>
              <p lang="es">
                Al acceder o usar los Servicios del Portal, usted acepta permanecer sujeto a estos
                Términos y Condiciones Adicionales. Estos Términos y Condiciones Adicionales
                constituyen un contrato vinculante entre DHI y usted (denominado en ocasiones,
                “usted” o “su” o “su familia”).
              </p>
              <p lang="en">
                Your access to and use of this Portal Services, which is provided by or on behalf of
                DHI, is also subject to the DHI’s Online Terms of Service, which is incorporated
                into these terms (the Supplemental Terms, the DHI Online Terms and of Services, and
                the DHI Privacy Policy are referred to collectively as the “Agreement”). However, if
                you have already signed a contract directly with DHI, the Online Terms of Service
                are not deemed part of the Agreement and are instead deemed replaced by such
                contract terms.
                <strong
                  >PLEASE READ THE ENTIRE AGREEMENT AND PRINT OR DOWNLOAD A COPY OF ALL OF THE
                  AGREEMENT FOR YOUR RECORDS.</strong
                >
              </p>
              <p lang="es">
                Su acceso y uso de estos Servicios del Portal, el cual es provisto por DHI o en su
                nombre, también está sujeto a los Términos y Condiciones del Servicio en Línea de
                DHI, los cuales se incorporan a estos términos (los Términos y Condiciones
                Adicionales, los Términos y Condiciones del Servicio en Línea de DHI y la Política
                de Privacidad de DHI se denominan, en forma conjunta, el “Acuerdo”). Sin embargo, si
                usted ya ha firmado un contrato directamente con DHI, los Términos y Condiciones del
                Servicio en Línea no se consideran parte del Acuerdo y, en cambio, se consideran
                reemplazados por los términos de dicho contrato.
                <strong
                  >LEA TODO EL ACUERDO E IMPRIMA O DESCARGUE UNA COPIA DE TODO EL ACUERDO PARA SU
                  REGISTRO.</strong
                >
              </p>
              <p lang="en">
                <strong
                  >YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE PORTAL SERVICES AT ALL TIMES
                  SATISFIES YOUR REQUEST OF DHI TO PROVIDE SECOND OPINION SERVICES THROUGH THE
                  PORTAL SERVICES AND IN THE MANNER YOU HAVE REQUESTED. IF YOU DO NOT AGREE WITH ALL
                  OF THE SUPPLEMENTAL TERMS AND CONDITIONS OF THE AGREEMENT, THEN YOU ARE NOT DEEMED
                  AN AUTHORIZED USER OF THE PORTAL SERVICES AND MAY NOT CREATE OR ATTEMPT TO CREATE
                  A PORTAL SERVICES ACCOUNT AND MAY NOT USE AND ARE PROHIBITED FROM USING (OR MUST
                  IMMEDIATELY STOP USING) THE PORTAL SERVICES.</strong
                >
              </p>
              <p lang="es">
                <strong
                  >USTED RECONOCE Y ACEPTA QUE SU USO DE LOS SERVICIOS DEL PORTAL EN TODO MOMENTO
                  SATISFACE SU SOLICITUD A DHI DE BRINDAR LOS SERVICIOS DE “SECOND OPINION” A TRAVÉS
                  DE LOS SERVICIOS DEL PORTAL Y DE LA MANERA SOLICITADA. SI USTED NO ACEPTA TODOS
                  LOS TÉRMINOS Y CONDICIONES ADICIONALES DEL ACUERDO, ENTONCES NO SE CONSIDERA UN
                  USUARIO AUTORIZADO DE LOS SERVICIOS DEL PORTAL Y NO PUEDE CREAR NI INTENTAR CREAR
                  UNA CUENTA DE SERVICIOS DEL PORTAL, TAMPOCO PODRÁ USAR LOS SERVICIOS DEL PORTAL Y
                  SE LE PROHÍBE HACERLO (O DEBE DEJAR DE USARLOS DE INMEDIATO).
                </strong>
              </p>
              <p lang="en">
                If you do not agree with the Agreement in full, we will consider your request via
                the Portal Services as withdrawn by you and you may not rely on any services already
                provided through it.
              </p>
              <p lang="es">
                Si usted no acepta la totalidad del Acuerdo, consideraremos que ha retirado su
                solicitud para acceder a los Servicios del Portal y no podrá contar con ningún
                servicio ya prestado a través de él .
              </p>
              <h3 lang="en">1. PORTAL SERVICES UNAVAILABILITY AND LIMITATIONS.</h3>
              <h3 lang="es">FALTA DE DISPONIBILIDAD Y LIMITACIONES DE LOS SERVICIOS DEL PORTAL</h3>
              <p lang="en">
                We want to make sure your Portal Services account is available, stays private and
                keeps in good working order. We also will improve and upgrade the Portal Services
                from time to time. So we can take such steps, your Portal Services account may be
                unavailable temporarily or the Portal Services’ performance degraded (lessened) from
                time to time. You acknowledge and agree that we may make your Portal Services
                account temporarily unavailable, or otherwise degrade their performance, under the
                following circumstances:
              </p>
              <p lang="es">
                Queremos asegurarnos de que su cuenta de Servicios del Portal está disponible,
                protegida y en buen funcionamiento. Ocasionalmente, además, mejoraremos y
                actualizaremos los Servicios del Portal. Por lo tanto, si tomamos esas medidas,
                ocasionales, su cuenta de los Servicios del Portal puede verse temporalmente
                inhabilitada o el funcionamiento de los Servicios del Portal puede verse reducido
                (degradado). Usted acepta que podemos inhabilitar temporalmente su cuenta de
                Servicios del Portal o degradar de otro modo su funcionamiento, en los siguientes
                casos:
              </p>
              <ul>
                <li>
                  <p lang="en">
                    To perform regularly scheduled or unscheduled maintenance and/or upgrades;
                  </p>
                  <p lang="es">
                    Para llevar a cabo tareas de mantenimiento o mejoras programadas periódicamente
                    o no programadas;
                  </p>
                </li>
                <li>
                  <p lang="en">For backup procedures or other system outages;</p>
                  <p lang="es">
                    Para procedimientos de copia de respaldo u otras interrupciones del sistema;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    To investigate or correct data corruption issues, such as if we reasonably
                    suspect there is misidentified, mismatched or corrupt data due to a technical
                    failure or other reason that is reasonably likely to endanger the life or
                    physical safety of a person;
                  </p>
                  <p lang="es">
                    Para investigar o corregir problemas de corrupción de datos, como, por ejemplo,
                    si sospechamos razonablemente que hay datos corruptos, erróneos o incompatibles,
                    debido a una falla técnica o por otra razón que es razonablemente probable que
                    ponga en peligro la vida o la seguridad física de una persona;
                  </p>
                </li>
                <li>
                  <p lang="en">In response to a security event or risk; and/or</p>
                  <p lang="es">En respuesta a un evento o riesgo de seguridad; o</p>
                </li>
                <li>
                  <p lang="en">
                    In response to an uncontrollable event, such as a natural or human-made
                    disaster, public health emergency, public safety incident, war, terrorist
                    attack, civil insurrection, strike or other labor unrest, telecommunication or
                    internet service interruption, or act of military, civil or regulatory
                    authority. If there is an uncontrolled event, we will take reasonable steps to
                    notify you that the Portal Services are unavailable, such as by posting a notice
                    on our website or communicating with you through any of the contact information
                    you have provided to us or
                    <a href="https://dignityhealthinternational.org/" target="_blank" rel="noopener"
                      >https://dignityhealthinternational.org</a
                    >".
                  </p>
                  <p lang="es">
                    En respuesta a un evento no controlado, como un desastre natural o un evento
                    causado por el hombre, una emergencia de salud pública, un incidente de
                    seguridad pública, guerra, ataque terrorista, revuelta civil, huelga u otra
                    protesta de empleados, interrupciones del servicio de internet o
                    telecomunicaciones, o un acto de autoridades militares, civiles o regulatorias.
                    Si hay un evento no controlado, tomaremos las medidas razonables para
                    notificarle que los Servicios del Portal no están disponibles, por ejemplo,
                    publicando una notificación en nuestro sitio web o comunicándonos con usted a
                    través de la información de contacto que nos ha proporcionado a nosotros o a
                    <a href="https://dignityhealthinternational.org/" target="_blank" rel="noopener"
                      >https://dignityhealthinternational.org</a
                    >".
                  </p>
                </li>
              </ul>
              <h3 lang="en">2. PORTAL SERVICES SAFETY ITEMS.</h3>
              <h3 lang="es">ELEMENTOS DE SEGURIDAD DE LOS SERVICIOS DEL PORTAL</h3>
              <p lang="en">
                <strong
                  >(a) Electronic Messaging. YOU SHOULD NOT USE THE PORTAL SERVICES TO COMMUNICATE
                  URGENT ISSUES TO A CONSULTING PHYSICIAN (“Consultant”).</strong
                >
                A Consultant may take up to five (5) business days or longer to respond to a message
                that you send through the Portal Services. If your established Consultant is
                unavailable, messages may be routed to another Consultant. If you have not received
                a response to your message, you should contact your DHI Case Manager.
              </p>
              <p lang="es">
                <strong
                  >Mensajería electrónica. NO DEBE USAR LOS SERVICIOS DEL PORTAL PARA COMUNICAR
                  ASUNTOS URGENTES A UN MÉDICO CONSULTOR (“Consultor”). </strong
                >Un Consultor puede demorar hasta cinco (5) días hábiles o más en responder a un
                mensaje enviado a través de los Servicios del Portal. Si su Consultor establecido no
                está disponible, es posible que sea derivado a otro Consultor. Si no ha recibido una
                respuesta a su mensaje, debe contactar a su Administrador de Caso de DHI.
              </p>
              <h3 lang="en">(b) Relationship to your Patient and Medical Record.</h3>
              <h3 lang="es">Relación con su paciente y el historial médico.</h3>
              <p lang="en">
                The Consultant will not have and will not seek to have a relationship with your
                patient, unless you refer your patient to the care of a Consultant. Accordingly, the
                Consultant is not deemed to be providing care to your patient. The Consultant will
                not be maintaining a medical record on your patient. The information provided on
                your patient and the opinion provided by the Consultant will be the only record
                maintained by the Portal Services. The retention period for this will be sixty (60)
                days after the opinion is issued, unless the Portal Services are no longer available
                as a service, in which case the information will not be available after more than
                five business days after issuance of the opinion.
              </p>
              <p lang="es">
                El Consultor no tendrá ni intentará tener una relación con su paciente, a menos que
                derive a su paciente a la atención de un Consultor. Del mismo modo, no se considera
                que el Consultor brinda atención médica a su paciente. El Consultor no mantendrá un
                registro médico de su paciente. La información provista sobre su paciente y la
                opinión provista por el Consultor serán el único registro mantenido por los
                Servicios del Portal. El período de retención será de sesenta (60) días después de
                emitida la opinión, a menos que los Servicios del Portal ya no estén disponibles
                como servicio, en cuyo caso, la información no estará disponible después de más de
                cinco días hábiles siguientes a la emisión de la opinión.
              </p>
              <h3 lang="en">(c) Opinion of Consultant.</h3>
              <h3 lang="es">Opinión de un Consultor.</h3>
              <p lang="en">
                The opinion provided by the Consultant is merely advisory and does not promise a
                particular outcome and is based entirely on the limited information on the patient
                provided via the Portal Services. The patient’s caregiver is responsible for all
                clinical care decisions; the Consultant shall not be liable for any such decisions
                made.
              </p>
              <p lang="es">
                El Consultor emite su opinión solo a modo de asesoramiento y no promete un resultado
                en particular y se basa completamente en la información limitada sobre el paciente
                provista a los Servicios del Portal. El cuidador del paciente es responsable por
                todas las decisiones clínicas, y el Consultor no será responsable por ninguna de
                ellas.
              </p>
              <h3 lang="en">3. Changes to These Supplemental Terms.</h3>
              <h3 lang="es">Cambios a estos Términos y Condiciones Adicionales</h3>
              <p lang="en">
                <strong
                  >WE MAY REVISE AND UPDATE THESE SUPPLEMENTAL TERMS FROM TIME TO TIME UPON NOTICE
                  TO YOU. ANY CHANGES TO THE AGREEMENT WILL BE POSTED ON HERE AND BE EFFECTIVE
                  IMMEDIATELY.</strong
                >
                Such posting shall constitute necessary notice to you. Note that if there are any
                inconsistencies between these Supplemental Terms and the updated DHI Online Terms of
                Service, these Supplemental Terms will govern.
                <strong
                  >YOU AGREE TO PERIODICALLY REVIEW THESE SUPPLEMENTAL TERMS FOR SUCH
                  CHANGES.</strong
                >
              </p>
              <p lang="es">
                <strong
                  >OCASIONALMENTE PODREMOS REVISAR Y ACTUALIZAR ESTOS TÉRMINOS Y CONDICIONES, LUEGO
                  DE ENVIARLE UNA NOTIFICACIÓN AL RESPECTO. LOS CAMBIOS AL ACUERDO SERÁN PUBLICADOS
                  AQUÍ Y ENTRARÁN EN VIGENCIA DE INMEDIATO.</strong
                >
                La publicación constituirá la notificación necesaria. En caso de que haya
                inconsistencias entre estos Términos y Condiciones Adicionales y los Términos y
                Condiciones del Servicio en Línea de DHI actualizados , estos Términos y Condiciones
                Adicionales prevalecerán.
                <strong
                  >USTED ACEPTA REVISAR PERIÓDICAMENTE ESTOS TÉRMINOS Y CONDICIONES ADICIONALES PARA
                  VER SI SE HAN HECHO CAMBIOS.</strong
                >
              </p>
              <h3 lang="en">Ownership of Portal Services.</h3>
              <h3 lang="es">Propiedad de los Servicios del Portal</h3>
              <p lang="en">
                We retain all ownership rights, control, and licenses to the Portal Services. Our
                Portal Services are subject to copyright, patent, trademark laws, and various other
                intellectual property rights under United States law, the laws of your country, and
                international conventions. You shall not or attempt to, nor will you allow or
                attempt to allow any third party (whether knowingly, directly or indirectly, whether
                or not for your benefit) to download, print, view, copy, republish, distribute,
                prepare derivative works, modify, alter, or otherwise use the Portal Services other
                than as expressly permitted by us in writing.
              </p>
              <p lang="es">
                Conservamos todos los derechos de propiedad, control y licencias de los Servicios
                del Portal. Nuestros Servicios del Portal están sujetos a las leyes de derechos de
                autor, patentes, marcas registradas y otros derechos de propiedad intelectual bajo
                la ley de los Estados Unidos, las leyes de su país y las convenciones
                internacionales. Usted no permitirá ni intentará permitir a ningún tercero (a
                sabiendas, directa o indirectamente, sea o no para su beneficio) descargar,
                imprimir, ver, copiar, volver a publicar, distribuir, preparar trabajos derivados,
                modificar, alterar o utilizar de cualquier otra manera los Servicios del Portal que
                no sea la permitida expresamente por nosotros por escrito.
              </p>
              <p lang="en">
                Any unauthorized use of the Portal Services or a use that is contrary to the terms
                and conditions of the Agreement is deemed to automatically terminate your right to
                access and use the Portal Services.
              </p>
              <p lang="es">
                El uso no autorizado de los Servicios del Portal o un uso contrario a los términos y
                condiciones del Acuerdo anulará automáticamente sus derechos de acceder y usar los
                Servicios del Portal.
              </p>
              <h3 lang="en">5. Our Intellectual Property Rights.</h3>
              <h3 lang="es">Nuestros Derechos de Propiedad Intelectual</h3>
              <p lang="en">
                The Portal Services, including, text, graphics, logos, button icons, photos, images,
                forms, audio, video, questionnaires, "look and feel" and software, is the property
                of us or our licensors, and are protected by United States and international
                copyright laws. Except as provided in the next paragraph, you are expressly
                prohibited from reproducing, duplicating, copying, publishing, selling or otherwise
                exploiting for any commercial purpose the Portal Services Content, in whole or in
                part, for any reason, unless prior written consent is obtained from the copyright
                holder(s). Copyright 2022. All rights reserved by Dignity Health International, LLC
                for all countries.
              </p>
              <p lang="es">
                Los Servicios del Portal, incluidos los textos, gráficos, logotipos, iconos de
                botones, fotos, imágenes, formularios, audio, vídeo, cuestionarios, "look and feel"
                y software, son propiedad nuestra o de nuestros licenciantes, y están protegidos por
                las leyes de derechos de autor de Estados Unidos e internacionales. Salvo lo
                dispuesto en el párrafo siguiente, se prohíbe expresamente reproducir, duplicar,
                copiar, publicar, vender o explotar de cualquier otro modo con fines comerciales el
                Contenido de los Servicios del Portal, en su totalidad o en parte, por cualquier
                motivo, a menos que se obtenga el consentimiento previo por escrito del titular o
                titulares de los derechos de autor. Copyright 2022. Todos los derechos reservados
                por Dignity Health International, LLC para todos los países.
              </p>
              <p lang="en">
                When granted login credentials by or on behalf of us, you may access and use the
                Portal Services for your personal use only, which is limited to communicating with
                our Consultants and with respect to a requesting physician also receiving a second
                opinion report pertaining to their patient’s medical condition. Any automated
                process or use of a bot for this purpose or any other purpose is strictly
                prohibited. Unless specifically authorized in writing by us, any other use of the
                Portal Services, other than communications by you and our second opinion as
                finalized, is expressly prohibited. Any rights not expressly granted by the terms
                and conditions of the Agreement are reserved by us.
              </p>
              <p lang="es">
                Cuando se le concedan credenciales de inicio de sesión por parte de nosotros o en
                nuestro nombre, podrá acceder y utilizar los Servicios del Portal únicamente para su
                uso personal, que se limita a la comunicación con nuestros Consultores y con
                respecto a un médico solicitante que también recibe un informe de segunda opinión
                relativo a la condición médica de su paciente. Cualquier proceso automatizado o el
                uso de un bot para este propósito o cualquier otro propósito está estrictamente
                prohibido. A menos que nosotros lo autoricemos específicamente por escrito, queda
                expresamente prohibido cualquier otro uso de los Servicios del Portal que no sea la
                comunicación por su parte y nuestra segunda opinión finalizada. Nos reservamos todos
                los derechos no concedidos expresamente por los términos y condiciones del Acuerdo.
              </p>
              <h3 lang="en">6. Your Content.</h3>
              <h3 lang="es">Su Contenido</h3>
              <p lang="en">
                Some of the Portal Services allow you or those acting on your behalf to submit
                content by posting messages, creating or modifying a home page, chatting, uploading
                files, inputting data, transmitting e-mail or engaging in any other form of
                communications (<strong>“Your Content”</strong>).
              </p>
              <p lang="es">
                Algunos de los Servicios del Portal le permiten a usted o a aquellos que actúan en
                su nombre enviar contenido, publicando mensajes, creando o modificando una página de
                inicio, chateando, cargando archivos, ingresando datos, transmitiendo correos
                electrónicos o participando de cualquier otra forma de comunicación (<strong
                  >“Su Contenido”</strong
                >).
              </p>
              <p lang="en">
                You retain ownership of any intellectual property rights that you hold in Your
                Content. When you submit Your Content to the Portal Services, you give us (and third
                parties we work with to provide the Portal Services) an unrestricted, worldwide,
                perpetual, royalty-free, irrevocable license and right to use, reproduce and modify
                Your Content in connection with operating, promoting and improving the Portal
                Services. We may translate, adapt, communicate, publish, publicly perform, publicly
                display, transmit and distribute Your Content, in all media now known or developed
                in the future. This license continues even if you stop using the Portal Services,
                except where you request in writing to us that we remove Your Content and we have
                means of readily identifying Your Content and can remove it in the ordinary course
                of providing the Portal Services. By submitting Your Content to the Portal Services
                or having others do it on your behalf, you are representing and warranting that you
                have the necessary rights to grant us the above license to use Your Content.
              </p>
              <p lang="es">
                Usted retiene los derechos de propiedad intelectual sobre Su Contenido. Cuando usted
                envía Su Contenido a los Servicios del Portal, nos otorga (a nosotros y a los
                terceros con los que trabajamos para brindar los Servicios del Portal) una licencia
                irrevocable, perpetua, ilimitada, mundial y sin regalías y el derecho a usar,
                reproducir y modificar Su Contenido en relación con la operación, promoción y mejora
                de los Servicios del Portal. Podemos traducir, adaptar, comunicar, publicar, actuar
                y exhibir públicamente, transmitir y distribuir Su Contenido en todos los medios
                conocidos actualmente o que se desarrollen en el futuro. Esta licencia continúa
                incluso si usted deja de usar los Servicios del Portal, excepto en caso de que usted
                nos solicite por escrito que eliminemos Su Contenido y nosotros tengamos los medios
                para identificar fácilmente Su Contenido y podamos eliminarlo durante el curso
                normal de la prestación de los Servicios del Portal. Al enviar Su Contenido a los
                Servicios del Portal, o hacer que otros lo envíen en su nombre, usted declara y
                garantiza que tiene los derechos necesarios para otorgarnos la licencia de uso sobre
                Su Contenido, como se menciona anteriormente.
              </p>
              <h3 lang="en">7. Your Account.</h3>
              <h3 lang="es">Su Cuenta</h3>
              <p lang="en">
                If you are permitted to, and choose to, create an account within the Portal
                Services, you are the only person authorized to access and use your account (“Your
                Account”). You may not give or disclose your username, password, or any other
                account information (collectively, “login credentials”) to a third party. You must
                immediately notify us of any known or suspected unauthorized access to your login
                credentials or use of Your Account, any attempt to do so or any known or suspected
                breach of security, including, but not limited to loss, theft, or unauthorized
                disclosure of your login credentials. Additionally, you may change your password at
                any time. YOU ARE RESPONSIBLE FOR ALL ACTIVITY ON YOUR ACCOUNT OR ATTRIBUTABLE TO
                YOUR LOGIN CREDENTIALS. Any fraudulent, misleading, abusive, or illegal activity may
                be grounds for termination of Your Account at our sole discretion. We may refer you
                to appropriate law enforcement agencies.
              </p>
              <p lang="es">
                Si usted recibe la autorización y decide crear una cuenta dentro de los Servicios
                del Portal, usted es la única persona autorizada a acceder y usar la cuenta (“Su
                Cuenta”). No puede proporcionar ni divulgar su nombre de usuario, contraseña ni otra
                información de la cuenta (denominados en forma conjunta, “credenciales de acceso”) a
                terceros. Debe notificarnos de inmediato si ha ocurrido, o sospecha que ha ocurrido,
                un acceso no autorizado a sus credenciales de acceso o un uso no autorizado de Su
                Cuenta, o cualquier intento de acceso o uso, o cualquier otro incidente de
                seguridad, incluidos, entre otros, la pérdida, el robo y la divulgación no
                autorizada de sus credenciales de acceso. Además, puede cambiar su contraseña en
                cualquier momento. USTED ES RESPONSABLE POR TODAS LAS ACTIVIDADES REALIZADAS EN SU
                CUENTA O ATRIBUIBLES A SUS CREDENCIALES DE ACCESO. Cualquier actividad fraudulenta,
                engañosa, abusiva o ilegal puede constituir una causal para cerrar Su Cuenta, a
                nuestro exclusivo criterio. Podemos denunciarlo a las agencias de orden público
                correspondientes.
              </p>
              <p lang="en">
                In some instances, your creation of an account within the Portal Services will give
                you the ability to access medical information that is maintained by a health care
                provider through the Portal Services.
              </p>
              <p lang="en">
                En algunos casos, la creación de una cuenta dentro de los Servicios del Portal le
                dará la capacidad de acceder a información médica guardada por un proveedor de
                atención médica a través de los Servicios del Portal.
              </p>
              <h3 lang="en">8. Your License to Access the Portal Services.</h3>
              <h3 lang="es">Su licencia de acceso a los Servicios del Portal.</h3>
              <p lang="en">
                We grant you a limited, revocable, nontransferable and nonexclusive license to
                access and make personal use of the Portal Services as specifically permitted
                herein. Any use of the Portal Services in excess of this license is strictly
                prohibited and constitutes a violation of the Agreement, which may result in the
                termination of your right to access and use the Portal Services. We retain the right
                to revoke the licenses and permissions granted hereunder without liability and to
                disable and deny access to any user for any reason or for no reason at all, with or
                without notice.
              </p>
              <p lang="es">
                Le concedemos una licencia limitada, revocable, intransferible y no exclusiva para
                acceder y hacer un uso personal de los Servicios del Portal según lo específicamente
                permitido en el presente documento. Cualquier uso de los Servicios del Portal que
                exceda esta licencia está estrictamente prohibido y constituye una violación del
                Acuerdo, que puede resultar en la terminación de su derecho a acceder y utilizar los
                Servicios del Portal. Nos reservamos el derecho a revocar las licencias y permisos
                concedidos en virtud del presente documento sin responsabilidad alguna y a
                deshabilitar y denegar el acceso a cualquier usuario por cualquier motivo o sin
                motivo alguno, con o sin previo aviso.
              </p>
              <h3 lang="en">9. Prohibitions</h3>
              <h3 lang="es">Prohibiciones</h3>
              <p lang="en">You agree that you will not use the Portal Services to:</p>
              <p lang="es">Usted acepta que no usará los Servicios del Portal para:</p>
              <ol type="A">
                <li>
                  <p lang="en">
                    Copy, modify, create derivative works, reverse engineer, decompile, disassemble,
                    or otherwise attempt to learn the source code, structure, or ideas upon which
                    the Portal Services are based;
                  </p>
                  <p lang="es">
                    Copiar, modificar, crear obras derivadas, manipular usando ingeniería inversa,
                    descompilar, desagrupar, o intentar obtener de cualquier otro modo, el código
                    fuente, la estructura o las ideas sobre las cuales se basan los Servicios del
                    Portal;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Introduce any malicious or intentionally destructive software, viruses, bugs, or
                    malware into the Portal Services designed to harm the Portal Services, or Our
                    Content;
                  </p>
                  <p lang="en">
                    Introducir cualquier software malicioso o intencionadamente destructivo, virus,
                    errores o malware en los Servicios del Portal diseñados para dañar los Servicios
                    del Portal, o Nuestro Contenido;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Use Our Content made available through the Portal Services in any manner that
                    misappropriates any trade secret or infringes any copyright, trademark, patent,
                    rights of publicity, or other proprietary right of any party;
                  </p>
                  <p lang="es">
                    Usar Nuestro Contenido disponible a través de los Servicios del Portal de
                    cualquier manera que resulte en la malversación de secretos comerciales o
                    infrinja los derechos de autor, marcas comerciales, patentes, derechos de
                    publicidad u otros derechos de propiedad intelectual de cualquier persona;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Attempt to access another person’s information, including personal information,
                    non-personal information;
                  </p>
                  <p lang="es">
                    Intentar acceder a la información de otra persona, incluida información personal
                    y no personal;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Post, upload, or transmit photos or video of any third party without express,
                    written permission. By uploading, transmitting, or posting any photo or video on
                    or through the Portal Services, you represent and warrant that you have express,
                    written permission from all persons appearing in the media for all potential
                    uses;
                  </p>
                  <p lang="es">
                    Publicar, cargar o transmitir fotografías o videos de terceros sin su permiso
                    expreso y escrito; Al cargar, transmitir o publicar fotografías o videos en o a
                    través de los Servicios del Portal, usted declara y garantiza que cuenta con el
                    permiso expreso y escrito de todas las personas que aparecen en esas fotografías
                    o videos para todos los usos potenciales;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Send any chain letters, junk mail, unauthorized e-mail, or advertisements;
                  </p>
                  <p lang="es">
                    Enviar cadenas de correo electrónico, correo electrónico no deseado, no
                    autorizado o publicidades;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Encourage any illegal activities, or post anything that is obscene, defamatory,
                    threatening, harassing, abusive, slanderous, hateful, or embarrassing to any
                    other person or entity;
                  </p>
                  <p lang="es">
                    Fomentar actividades ilícitas o publicar contenido obsceno, difamatorio,
                    amenazante, acosador, abusivo, calumnioso, humillante o de odio dirigido hacia
                    cualquier otra persona o entidad;
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Decrypt, transfer, frame, display, or translate any part of the Portal Services;
                    or
                  </p>
                  <p lang="es">
                    Descifrar, transferir, enmarcar, exponer o traducir cualquier parte de los
                    Servicios del Portal; o
                  </p>
                </li>
                <li>
                  <p lang="en">
                    Use the information in the Portal Services to create or sell a similar service
                    or similar information.
                  </p>
                  <p lang="es">
                    Usar la información en los Servicios del Portal para crear o vender un servicio
                    similar o información similar.
                  </p>
                </li>
              </ol>
              <h3 lang="en">10. Deactivation of Your Account</h3>
              <h3>Desactivación de Su Cuenta</h3>
              <p lang="en">
                We may modify, suspend, or close Your Account or otherwise stop providing the Portal
                Services in whole or in part at any time at our sole discretion. We will not be
                liable to you or any third party for suspension or termination of the Portal
                Services or Your Account. You will not be able to access any information in Your
                Account once your access has been suspended or terminated or the Portal Services
                itself have been shut down.
                <strong
                  >You are responsible for making sure Your Content and other information that you
                  are specifically authorized to copy is properly backed up, so you have access to
                  it in the event of loss, corruption, or cessation of the Portal Services, or
                  termination or suspension of Your Account.</strong
                >
                We do not and are not obligated to export Your Content or other data relating to
                your use of the Portal Services, or provide a copy of Your Content or other data to
                you once Your Account is closed.
              </p>
              <p lang="es">
                Podemos modificar, suspender o cerrar Su Cuenta, o dejar de brindarle los Servicios
                del Portal, en todo o en parte, en cualquier momento, a nuestro exclusivo criterio.
                No seremos responsables ante usted ni ninguna otra persona por la suspensión o
                finalización de los Servicios del Portal o Su Cuenta. Una vez que su acceso haya
                sido suspendido o anulado o los Servicios del Portal hayan finalizado, usted no
                podrá acceder a ninguna información de Su Cuenta.
                <strong
                  >Usted es responsable de asegurarse de contar con una copia de respaldo de Su
                  Contenido u otra información que esté específicamente autorizado a copiar, para
                  poder acceder a ella en caso de pérdida, corrupción o finalización de los
                  Servicios del Portal, o suspensión o anulación de Su Cuenta.</strong
                >No estamos obligados a exportar y no exportamos Su Contenido ni otros datos
                relacionados con su uso de los Servicios del Portal, ni a brindar una copia de Su
                Contenido u otros datos, una vez que Su Cuenta esté cerrada.
              </p>
              <h3 lang="en">11. Indemnity</h3>
              <h3 lang="es">Indemnidad</h3>
              <p lang="en">
                By submitting any personal information to the Portal Services or having others do it
                on your behalf, you are representing and warranting that any and all personal
                information to be provided by you shall fully comply with all standards, laws,
                statutes, restrictions, ordinances, requirements, and regulations, including, but
                not limited to the Personal Information Protection Law of the People's Republic of
                China (“PRC”) and the Data Security Law of the PRC, both published by the Standing
                Committee of the National People's Congress, and any other Laws as may be amended
                from time to time in the PRC or other applicable jurisdictions in relation to
                personal information protection and cross-border data transmission (collectively
                <strong>“Laws”</strong>).
              </p>
              <p lang="es">
                Al enviar información personal a los Servicios del Portal, o hacer que otros lo
                hagan en su nombre, usted declara y garantiza que toda la información personal
                provista por usted cumplirá con todos los estándares, leyes, estatutos,
                restricciones, ordenanzas, requisitos y regulaciones, incluidas, entre otras, la Ley
                de Protección de Datos Personales de la República Popular de China (“RPC”) y la Ley
                de Seguridad de Datos de la RPC, ambas publicadas por el Comité Permanente de la
                Asamblea Popular Nacional de la RPC, y cualquier otra ley y sus modificaciones en la
                RPC u otras jurisdicciones aplicables en relación a la protección de la información
                personal y la transmisión internacional de datos (denominadas en forma conjunta, las
                <strong>“Leyes”</strong>).
              </p>
              <p lang="en">
                You shall be solely responsible for fulfilling all requirements under the applicable
                Laws, including, but not limited to obtaining prior written and separate consent
                from each patient for collection, processing, use and transmission of personal data,
                and informing such patients of matters regarding DHI and the Portal Service as the
                name and contact information, purpose and method of processing, type of personal
                information and the method and procedure for the patients to exercise the rights
                stipulated under the Laws. In the event that any competent authority under the Laws
                requires DHI to take any action in relation to the protection of personal
                information or compliance issues on cross-border data transfer, you shall fully
                cooperate with DHI and take full responsibility for the results thereof.
              </p>
              <p lang="es">
                Usted será el único responsable de cumplir con todos los requisitos de las Leyes
                aplicables, entre los que se incluyen, obtener un consentimiento previo por escrito
                independiente para cada paciente para la recopilación, el procesamiento, el uso y la
                transmisión de datos personales, e informar a dichos pacientes sobre asuntos
                relacionados con DHI y los Servicios del Portal, como el nombre y la información de
                contacto, el propósito y el método de procesamiento, el tipo de información
                personal, y el método y procesamiento para que los pacientes ejerciten los derechos
                amparados por las Leyes. En caso de que una autoridad competente en virtud de las
                Leyes solicite a DHI tomar alguna medida en relación con la protección de sus datos
                personales o algún problema de cumplimiento en la transferencia internacional de
                datos, usted deberá brindar su total cooperación a DHI y será completamente
                responsable de los resultados de dichas medidas.
              </p>
              <p lang="en">
                You agree to defend, indemnify, and hold us, our officers, members, directors,
                employees, contractors, and agents (“Indemnified Party” or collectively “Indemnified
                Parties”), from and against any and all claims, actions, losses or expenses,
                including attorneys’ fees and costs and expenses, arising out of or in any way
                connected with (a) your access to or use of all or part of the Portal Services; (b)
                any personal information you provide to the Portal Services; (c) an alleged or
                actual breach by you of these Supplemental Terms; (d) your infringement or
                misappropriation of our IP; or (e) any negligence or willful misconduct by you.
              </p>
              <p lang="es">
                Usted acepta defender, indemnizar y mantener indemne a la empresa, nuestros
                funcionarios, miembros, directores, empleados, contratistas y agentes (“Parte
                Indemnizada” o, en forma conjunta, “Partes Indemnizadas”), contra cualquier reclamo,
                acción judicial, pérdida o gasto, que surja o se relacione de cualquier modo con (a)
                su acceso o uso de todo o parte de los Servicios del Portal; (b) cualquier
                información personal proporcionada a los Servicios del Portal; (c) un
                incumplimiento, real o sospechado, por su parte de estos Términos y Condiciones
                Adicionales; (d) su infracción o malversación de Nuestra PI; o (e) cualquier acto
                negligente o doloso de su parte.
              </p>
              <p lang="en">
                You will defend the Indemnified Parties, at your expense, from any claim, liability,
                damage, or loss or expense, referenced above; provided, however, you will not
                obligate the Indemnified Parties in any respect in connection with any settlement
                without the prior written consent of an Indemnified Party. If you fail to assume
                your obligation to defend, the Indemnified Parties may do so to protect their
                interests and you will reimburse all costs incurred by an Indemnified Party in
                connection with such defense, including attorneys’ fees and costs and expenses.
              </p>
              <p lang="es">
                Usted defenderá a las Partes Indemnizadas, a su propio cargo, contra cualquiera de
                los gastos, responsabilidad, daños y perjuicios, pérdidas o gastos, mencionados
                anteriormente; estableciéndose, sin embargo, que usted no obligará de ningún modo a
                las Partes Indemnizadas en relación con ninguna conciliación sin el consentimiento
                escrito previo de una de las Partes Indemnizadas. Si usted no asume su obligación de
                defender, las Partes Indemnizadas pueden hacerlo para proteger sus intereses y usted
                deberá reembolsarles todos los costos incurridos en relación con dicha defensa,
                incluidos los honorarios de abogados y las costas legales.
              </p>
              <h3 lang="en">12. Choice of Law</h3>
              <h3 lang="es">Legislación aplicable</h3>
              <p lang="en">
                These Supplemental Terms are governed by the laws of the State of California, USA,
                exclusive of its choice of law rules.
              </p>
              <p lang="es">
                Estos Términos y Condiciones Adicionales están regidos por las leyes del Estado de
                California, Estados Unidos, según las normas relativas a elección de fuero.
              </p>
              <h3 lang="en">13. Dispute Resolution</h3>
              <h3 lang="es">Resolución de disputas</h3>
              <p lang="en"><strong>1. Arbitration</strong></p>
              <p lang="es"><strong>Arbitraje</strong></p>
              <p lang="en">
                You agree that any dispute, action, or claim relating in any manner to these
                Supplemental Terms or the Portal Services will be resolved, individually and not in
                a class action, by binding arbitration in San Francisco County, California pursuant
                to the Commercial Arbitration Rules of the American Arbitration Association (“AAA”)
                and as applicable with the assistance of the AAA’s International Centre for Dispute
                Resolution. The arbitrator, and not any federal, state, or local court or agency,
                shall have exclusive authority to resolve any dispute relating to the
                interpretation, applicability, enforceability, or formation of these Supplemental
                Terms, including any claim that all or any part, of these Supplemental Terms is void
                or voidable. Notwithstanding the foregoing, either party may seek any interim or
                preliminary relief from a court of competent jurisdiction in San Francisco County,
                California as necessary to protect the party’s rights or property.
              </p>
              <p lang="es">
                Usted acepta que cualquier disputa, acción judicial o reclamo relacionado de
                cualquier modo con estos Términos y Condiciones Adicionales o los Servicios del
                Portal será resuelta, de manera individual y no como demanda colectiva, mediante
                arbitraje vinculante en el Condado de San Francisco, California, de conformidad con
                las Reglas de Arbitraje Comercial de la Asociación Estadounidense de Arbitraje
                (“AAA”) y, según corresponda, con asistencia del Centro Internacional para
                Resolución de Disputas de la AAA. El árbitro, y no una agencia ni un tribunal
                federal, estatal o local, tendrá la facultad exclusiva de resolver cualquier disputa
                con relación a la interpretación, aplicación, ejecución o formación de estos
                Términos y Condiciones Adicionales, incluido cualquier reclamo de que parte o la
                totalidad de estos Términos y Condiciones Adicionales son nulos o anulables. Sin
                perjuicio de lo anterior, cualquiera de las partes puede intentar obtener una medida
                cautelar o preliminar de un tribunal con competencia del Condado de San Francisco,
                California, según sea necesario para proteger sus derechos o propiedad.
              </p>
              <p lang="en"><strong>2. Class Action Waiver</strong></p>
              <p lang="en"><strong>Renuncia al derecho a la demanda colectiva</strong></p>
              <p lang="en">
                A class action, consolidated action, or representative action is a legal proceeding
                brought by a group of people, with an identical claim, who are represented together
                by a member of the group.
              </p>
              <p lang="es">
                Una demanda colectiva, una acción consolidada o acción representativa es un proceso
                legal iniciado por un grupo de personas con idénticos reclamos, que están
                representadas en conjunto por un miembro del grupo.
              </p>
              <p lang="en">
                By agreeing to these Supplemental Terms, you agree that any dispute, action, or
                claim will be resolved on an individual basis and not in a class action,
                consolidated action, or representative action. If, for any reason, any dispute,
                action, or claim proceeds in court rather than in arbitration, you agree to only
                bring your claim on an individual basis and not as part of a class action,
                consolidated action, or representative action. You agree to waive any rights that
                you may have to bring a claim in a class action, consolidated action, or
                representative action.
              </p>
              <p lang="es">
                Al aceptar estos Términos y Condiciones Adicionales, usted acepta que cualquier
                disputa, acción judicial o reclamo será resuelto de manera individual y no mediante
                una demanda colectiva, una acción consolidada o una acción representativa. Si, por
                cualquier motivo, alguna de las disputas, acciones judiciales o reclamos se tramita
                en un tribunal, en lugar de resolverse en arbitraje, usted acepta iniciar únicamente
                su reclamo de manera individual y no como parte de una demanda colectiva, una acción
                consolidada o una acción representativa. Usted acepta renunciar a cualquier derecho
                que pudiera tener a iniciar un reclamo en una demanda colectiva, una acción
                consolidada o una acción representativa.
              </p>
              <p lang="en">A. Questions about the Supplemental Terms.</p>
              <p lang="es">Preguntas acerca de estos Términos y Condiciones Adicionales.</p>
              <p lang="en">
                If you have any questions about these Supplemental Terms, please contact us at:
              </p>
              <p lang="es">
                Si tiene preguntas acerca de estos Términos y Condiciones Adicionales, contáctenos
                a:
              </p>
              <p lang="en">Email: XXX</p>
              <p lang="es">Correo electrónico: XXX</p>
              <p fxLayout="row" fxLayoutAlign="end">
                <mat-checkbox formControlName="supplemental" (click)="accepted('supplemental')"
                  >I accept/Acepto</mat-checkbox
                >
              </p>
            </section>
          </mat-expansion-panel>

          <div
            class="action-row"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="16px"
          >
            <div fxLayout="row" fxLayoutAlign="start center"></div>
            <div groupGroupName="userData" fxLayout="row" fxLayoutAlign="end center">
              <div></div>
              <mat-checkbox
                [disabled]="(isAllNoticesAccepted() | async) === false"
                formControlName="legalNoticedAccepted"
                labelPosition="before"
              >
                <span>
                  Check here to acknowledge you have read and understand the terms of service.<br />Marque
                  aquí para reconocer que ha leído y entendido los términos de servicio.
                </span>
              </mat-checkbox>
              <button
                mat-flat-button
                color="primary"
                type="submit"
                [disabled]="(isAllAccepted() | async) === false"
                (click)="onAccept()"
              >
                Accept/ Aceptar
              </button>
            </div>
          </div>
        </mat-accordion>
      </form>
    </mat-card>
  </div>
</ng-template>
