import { HttpErrorResponse } from "@angular/common/http";
import { EventBase, IEvent } from "@vp/shared/event-aggregator";

export class ScheduleServiceErrorResponse
  extends EventBase<ScheduleServiceEndpointException>
  implements IEvent
{
  constructor(private response: ScheduleServiceEndpointException) {
    super();
    this.args = this.response;
  }
}

export interface ScheduleServiceEndpointException {
  error: HttpErrorResponse;
  message: string;
}
