import { Breakpoints } from "@angular/cdk/layout";
import { BREAKPOINT } from "@angular/flex-layout";

const BREAKOUT_BREAKPOINTS = [
  {
    alias: "min1455",
    mediaQuery: "screen and (min-width: 1455px)",
    overlapping: false
  }
];

export const CUSTOM_BREAKPOINTS_PROVIDER = {
  provide: BREAKPOINT,
  useValue: BREAKOUT_BREAKPOINTS,
  multi: true
};

export const MOBILE_BREAKPOINTS = [
  Breakpoints.XSmall,
  Breakpoints.Small,
  Breakpoints.TabletPortrait,
  Breakpoints.WebPortrait
];

export const DESKTOP_BREAKPOINTS = [
  Breakpoints.Medium,
  Breakpoints.Large,
  Breakpoints.XLarge,
  Breakpoints.WebLandscape,
  Breakpoints.TabletLandscape
];
