/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { FieldType } from "@ngx-formly/material";
import { DateTimeAdapter, DATE_TIME_FORMAT } from "./date-time.adapter";

export enum DateDirection {
  Past = "past",
  Future = "future",
  Both = "both"
}

@Component({
  selector: "lib-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: DateTimeAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_TIME_FORMAT
    }
  ]
})
export class DateTimePickerComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
  formControl!: FormControl;
  maxDate!: Date | null;
  minDate!: Date | null;

  @ViewChild("picker") picker: any;

  defaultOptions = {
    templateOptions: {
      disabled: false,
      showSpinners: true,
      showSeconds: false,
      touchUi: false,
      enableMeridian: false,
      minDate: null,
      maxDate: null,
      dateDirection: "both",
      stepHour: 1,
      stepMinute: 1,
      stepSecond: 1,
      color: "primary",
      disableMinute: false,
      hideTime: false
    }
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.to.dateDirection === DateDirection.Past) {
      this.maxDate = new Date();
      this.minDate = null;
    } else if (this.to.dateDirection === DateDirection.Future) {
      this.maxDate = null;
      this.minDate = new Date();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
