import { Inject, Injectable, Injector } from "@angular/core";
import { NotificationEvent, OrganizationFeatures } from "@vp/models";
import { EventAggregator, IEvent } from "@vp/shared/event-aggregator";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { combineLatest, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

//these classes are stored within toastr.css within the ngx-toastr module
const validPositionClasses = [
  "toast-top-right",
  "toast-bottom-right",
  "toast-bottom-left",
  "toast-top-left",
  "toast-top-full-width",
  "toast-bottom-full-width",
  "toast-top-center",
  "toast-bottom-center",
  "toast-center-center"
];

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private positionClass: string[] = [];

  constructor(
    @Inject(Injector) private injector: Injector,
    eventAggregator: EventAggregator,
    private featureService: FeatureService
  ) {
    eventAggregator
      .on<NotificationEvent>(NotificationEvent)
      .pipe(
        map((e: IEvent) => e as NotificationEvent),
        mergeMap(e => {
          return combineLatest([
            of(e),
            this.injector.get(ToastrService)[e.args.type](e.args.message, e.args.title).onShown
          ]);
        })
      )
      .subscribe(([ne, _]: [NotificationEvent, void]) => {
        ne.handled.next(true);
      });

    this.featureService
      .configurationLists$(OrganizationFeatures.toastrPositionClass)
      .pipe(filterNullMap())
      .subscribe((featureData: Record<string, string[]>) => {
        this.positionClass = featureData["positionClass"];
      });
  }

  private checkPositionClass(options: Partial<IndividualConfig>): Partial<IndividualConfig> {
    if (this.positionClass.length > 0) {
      if (validPositionClasses.includes(this.positionClass[0])) {
        return { ...options, positionClass: this.positionClass[0] };
      }
    }
    return options;
  }

  successMessage(
    message: string,
    title: string = "Success",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector.get(ToastrService).success(message, title, this.checkPositionClass(options));
  }

  errorMessage(
    message: string,
    title: string = "Error",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector.get(ToastrService).error(message, title, this.checkPositionClass(options));
  }

  infoMessage(
    message: string,
    title: string = "Info",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector.get(ToastrService).info(message, title, this.checkPositionClass(options));
  }

  warningMessage(
    message: string,
    title: string = "Warning",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector.get(ToastrService).warning(message, title, this.checkPositionClass(options));
  }
}
