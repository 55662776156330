import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CasePaymentRefund, TransactionViewModel } from "@vp/models";

@Component({
  selector: "vp-cybersource-refund-dialog",
  templateUrl: "./cybersource-refund-dialog.component.html",
  styleUrls: ["./cybersource-refund-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CybersourceRefundDialogComponent {
  form: FormGroup;
  allowPartialRefund = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public payment: TransactionViewModel,
    private readonly dialogRef: MatDialogRef<CybersourceRefundDialogComponent>,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      amount: [
        this.getAvailableRefunds(),
        [Validators.required, Validators.max(Number(this.getAvailableRefunds()))]
      ],
      notes: ["", Validators.required]
    });
  }

  getAvailableRefunds() {
    if (this.payment.refunds) {
      const amountRefunded = this.payment.refunds.reduce(
        (sum, current) => sum + Number(current.amount),
        0
      );
      return (Number(this.payment.amount) - amountRefunded).toFixed(2);
    }
    return Number(this.payment.amount).toFixed(2);
  }

  refund() {
    let refundPayload = {
      amount: this.form.value.amount,
      notes: this.form.value.notes
    } as CasePaymentRefund;
    this.dialogRef.close(refundPayload);
  }

  onPartialRefundToggle() {
    if (this.allowPartialRefund === false) {
      this.form.controls["amount"].setValue(this.getAvailableRefunds());
    }
  }
}
