import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from "@angular/material/form-field";
import PhoneNumber from "awesome-phonenumber";
import { Subject } from "rxjs";
import { ISO_3166_1_CODES } from "./country-codes";

/**
 * Custom `MatFormFieldControl` for telephone number input.
 *
 * The PhoneComponent presents a country selector and phone number
 * field that formats the phone number according to the selected
 * country's number standard.  The available awesome-phonenumber
 * metadata are presented as the phone number is entered.
 */
@Component({
  selector: "vp-phone-input",
  templateUrl: "./phone-input.component.html",
  styleUrls: ["./phone-input.component.scss"],
  providers: [{ provide: MatFormFieldControl, useExisting: PhoneInputComponent }]
})
export class PhoneInputComponent
  implements ControlValueAccessor, MatFormFieldControl<string | null>, OnDestroy
{
  static nextId = 0;
  id = `tel-input-${PhoneInputComponent.nextId++}`;

  private _disabled = false;
  private _placeholder = "Enter Phone Number";
  private _required = false;

  autofilled?: boolean | undefined;
  controlType = "tel-input";
  countyCodes = ISO_3166_1_CODES;
  focused = false;
  formControl!: FormControl;

  phoneErrorMatcher = new PhoneErrorMatcher();
  formGroup: FormGroup;
  stateChanges = new Subject<void>();
  touched = false;

  onChange(_val: any) {
    return;
  }
  onTouched() {
    return;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    if (this.formGroup.valid) {
      const n = this.formGroup.value;
      return `${n.phone.countryCode} | ${n.phone.number}`;
    }
    return null;
  }
  set value(tel: string | null) {
    const parts: string[] = this.parseTelephoneString(tel);
    const countryCode = parts[0];
    const number = parts[1];
    this.formGroup.setValue({
      phone: {
        countryCode: countryCode,
        number: number
      }
    });
    this.stateChanges.next();
  }

  private parseTelephoneString = (tel: string | null) => {
    let parts: string[] = ["US", ""];
    if (tel) {
      if (tel.includes("|")) {
        parts = tel.split("|").map(part => part.trim());
      } else {
        const phoneNumber: PhoneNumber = new PhoneNumber(tel, "US");
        parts = ["US", phoneNumber.getNumber("international")];
      }
    }
    return parts;
  };

  get empty() {
    const {
      value: {
        phone: { countryCode, number }
      }
    } = this.formGroup;

    return !countryCode && !number;
  }

  get errorState(): boolean {
    return this.formGroup.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.formGroup = formBuilder.group({
      phone: formBuilder.group(
        {
          countryCode: "US",
          number: ""
        },
        { validators: phoneValidator }
      )
    });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, "program");
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(".tel-input-container");
    controlElement?.setAttribute("aria-describedby", ids.join(" "));
  }

  onContainerClick() {
    return;
  }

  writeValue(tel: string | null): void {
    if (tel === null) tel = "US | ";
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Return an {@see PhoneNumber} value created from the
   * phoneNumberDigits and currently selected country code.
   */
  get phoneNumber(): PhoneNumber {
    return new PhoneNumber(this.phoneNumberControl.value, this.phoneCountryControl.value);
  }

  /**
   * Formats the phone number digits using the 'international' format
   * from awesome-phonenumber.
   */
  formatNumber() {
    const num = this.phoneNumber.getNumber("international");
    this.phoneNumberControl.setValue(num ? num : this.phoneNumberControl.value);
    if (this.formGroup.valid) this.onChange(this.value);
  }

  /**
   * Generate a hint using the {@see PhoneNumber} getExample method
   * with the currently selected country.
   */
  get phoneHint(): string {
    return PhoneNumber.getExample(this.phoneCountryControl.value).getNumber("international");
  }

  /**
   * Get the [E.164]{@link https://en.wikipedia.org/wiki/E.164} formatted
   * phone number typically required by systems for making calls and
   * sending text messages.
   */
  get phoneE164(): string {
    return this.phoneNumber.getNumber("e164");
  }

  get phoneCountryControl() {
    return this.formGroup.get("phone.countryCode") as FormControl;
  }

  get phoneNumberControl() {
    return this.formGroup.get("phone.number") as FormControl;
  }
}

/**
 * Validates a FormGroup containing `country` and `number` fields that
 * are used to generate a {@see PhoneNumber}. Valid numbers are
 * determined by the PhoneNumber.isValid() method.
 */
export const phoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const country = control.get("countryCode");
  const num = control.get("number");
  if (num?.value && country?.value && !new PhoneNumber(num.value, country.value).isValid()) {
    return { invalidPhone: true };
  } else {
    return null;
  }
};

/**
 * {@see ErrorStateMatcher} used to update the error state of the
 * phone number when the country or phone number changes.
 */
export class PhoneErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control?.value && control.touched && !control.parent?.valid);
  }
}
