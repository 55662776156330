export enum SignalrMethods {
  newCaseChat = "newCaseChat",
  newAssignUser = "newAssignUser",
  updatedCase = "updatedCase",
  movementInRoomDetected = "movementInRoomDetected",
  callLightActivated = "callLightActivated",
  callLightDeactivated = "callLightDeactivated",
  interactiveSessionEnded = "interactiveSessionEnded",
  interactiveSessionStarted = "interactiveSessionStarted",
  deviceConnectionChanged = "deviceConnectionChanged",
  devicePowerStatusUpdated = "devicePowerStatusUpdated",
  deviceMicrophonesUpdated = "deviceMicrophonesUpdated",
  deviceSpeakersUpdated = "deviceSpeakersUpdated",
  deviceCamerasUpdated = "deviceCamerasUpdated",
  deviceNetworkInterfacesUpdated = "deviceNetworkInterfacesUpdated",
  deviceStethoscopesUpdated = "deviceStethoscopesUpdated",
  caseDataChanged = "caseDataChanged",
  roomAssignedToDevice = "roomAssignedToDevice",
  roomUnassignedFromDevice = "roomUnassignedFromDevice",
  messageToPatient = "messageToPatient",
  caseStatusChanged = "caseStatusChanged"
}
