import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  AfterViewInit,
  Output
} from "@angular/core";

/**
 * Directive to bind a property to the input focus state and control it
 * through the property.
 *
 * Example:
 *   <input
 *     [(vpFocusState)]="focused"
 *   />
 * Or:
 *   <input
 *     [vpFocusState]="focused"
 *     (vpFocusStateChange)="focused = $event"
 *   />
 */
@Directive({
  selector: "input[vpFocusState]"
})
export class FocusStateDirective implements AfterViewInit {
  private _condition = true;

  @Input()
  set vpFocusState(value: string | boolean) {
    this._condition = typeof value === "boolean" ? value : true;
    this.setFocus();
  }

  @Output()
  vpFocusStateChange = new EventEmitter<boolean>();

  @HostListener("focus")
  setInputFocus(): void {
    this.vpFocusStateChange.emit(true);
  }

  @HostListener("blur")
  setInputFocusOut(): void {
    this.vpFocusStateChange.emit(false);
  }

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.setFocus();
  }

  private setFocus() {
    if (this._condition) {
      this.elementRef.nativeElement.focus();
      this.vpFocusStateChange.emit(true);
      this.cdr.detectChanges();
    }
  }
}
