<div class="input-container">
  <a
    *ngIf="url"
    mat-icon-button
    matPrefix
    [href]="url"
    target="_blank"
    matTooltip="Open link in a new tab"
    class="link"
  >
    <mat-icon>link</mat-icon>
  </a>
  <input matInput type="input" [formControl]="formControl" [formlyAttributes]="field" />
</div>
